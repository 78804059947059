export const mutations = {
  SET_PCP_PLANNING_SHEDS(state, value) {
    state.pcpPlanningSheds = value;
  },

  SET_TOTAL_SHEDS(state, value) {
    state.totalSheds = value;
  },

  SET_SELECTED_SHEDS_ROWS(state, value) {
    state.reportSelectedList = value;
  },

  SET_SLAUGHTER_WEIGHT_PROJECTION(state, value) {
    state.slaughterWeightProjection = value;
  },

  SET_TABLE_OPTION(state, value) {
    state.tableOptions = value;
  }
};
