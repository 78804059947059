import {
  getSlaughterList,
  getSlaughterWeightProjection
} from '@/services/slaughter'

export const actions = {
  async loadSheds({ rootGetters, commit }, { params = [], page, itemsPerPage }) {
    const token = rootGetters['user/getToken'];
    let response = await getSlaughterList(
      token.access,
      itemsPerPage,
      (page - 1) * itemsPerPage,
      params
    );

    let pcpPlanningSheds = []
    if (response.results) {
      response.results.forEach((element) => {
        pcpPlanningSheds.push({
          'id': element.id,
          'name': element.name,
          'producer': element.name,
          'age': element.last_batch?.age ?? '--',
          'weight': element.weight ?? '--',
          'performance': element.performance ?? '--',
          'target_date': element.target_date ?? '--',
          'target_age': element.target_age ?? '--',
          'target_weight': element.target_weight ?? '--',
          'target_value': element.target_value ?? '--',
          'target_achieved': element.target_achieved ?? false,
          'last_batch_id': element.last_batch?.id
        });
      });
    }
    commit('SET_PCP_PLANNING_SHEDS', pcpPlanningSheds);
    commit('SET_TOTAL_SHEDS', response.count);
  },

  async loadSlaughterWeightProjection({ rootGetters, commit }, { last_batch_id }) {
    const token = rootGetters['user/getToken'];
    let response = await getSlaughterWeightProjection(
      token.access,
      last_batch_id
    );

    let slaughterWeightProjection = []
    if (response.results) {
      slaughterWeightProjection = response.results.predictions
    }
    commit('SET_SLAUGHTER_WEIGHT_PROJECTION', slaughterWeightProjection);
  },
};
