export default {
  'comfort': 'Comodidad',
  'comfortable': 'Cómodo',
  'intermediary': 'Intermediario',
  'critical': 'Crítico',
  'lethal': 'Letal',
  'no_data': 'Sin Datos',
  'aviaries': 'Pajareras',
  'aviary': 'Pajarera',
  'loading_properties': 'Cargando propiedades',
  'none_found': 'No se encontraron propiedades',
  'empty_batch': 'Lote Vacío',
  'pre_lodged': 'Pre-alojamiento',
  'lodged': 'Alojado',
  'slaughter': 'Carnear',
  'priority': 'Prioridad',
  'filtros': 'Filtros',
  'simulation': 'Simulación',
  'aviaries_page': 'Pajareras por Página',
  'rations_page': 'Comidas por Página',
  'entry_weight': 'Peso de Llegada',
  'weekly_weight_projection': 'Proyección de Peso por Edad',
  'conversionFactor': 'Factor de Conversión',
  'target_weight': 'Peso Objetivo',
  'target_value': 'Ración necesaria',
  'leave_weight': 'Peso de salida',
  'leave_age': 'Edad de salida',
  'stage': 'Fase',
  'shed': 'Cobertizo',
  'bed_temperature': 'Temperatura de la cama',
  'water_temperature': 'Temperatura de la agua',
  'ambient-humidity': 'Humedad ambiental',
  'humidity-ambient-temperature': 'Humedad ambiental e Temperatura',
  'ambient-temperature': 'Temperatura Ambiente',
  'lineage': 'Linaje',
  'sex': 'Sexo',
  'age': 'Edad',
  'real_age': 'Edad del Ave',
  'batch_age': 'Edad del Lote',
  'alerts/recent/': 'Alertas',
  'alerts/history/': 'Historial de Alertas',
  'weight': 'Peso',
  'dwg': 'A.P.D',
  'uniformity': 'Uniformidad',
  'performance': 'Desempeño',
  'alertTypeQty': 'Tipos',
  'totalQty': 'Quantidade',
  'producer': 'Productor',
  'city': 'Ciudad',
  'greater_than': 'Mayor que',
  'less_than': 'Menos que',
  'exactly': 'Exactamente',
  'add': 'Añadir',
  'remove': 'Remover',
  'and': 'Y',
  'ambience': 'Ambiente',
  'type': 'Tipo',
  'message': 'Mensaje',
  'alerts': 'Alertas',
  'creation_date': 'Fecha de Creación',
  'qty24h': 'Últimas 24h',
  'subtype': 'Subtipo',
  'identification': 'Identificación',
  'address': 'Dirección',
  'contact': 'Contacto',
  'sub_region': 'Sub-región',
  'lodge': 'Alojamiento',
  'current_lodge': 'Alojamiento Actual',
  'date': 'Fecha',
  'effective_weight': 'Peso Efectivo',
  'nutrition': 'Nutrición',
  'sanity': 'Cordura',
  'logout': 'Salir',
  'loading': 'Cargando',
  'en': 'Inglés',
  'es': 'Español',
  'pt-br': 'Portugués',
  'slaughter_forecast': 'Pronóstico de Carnear',
  'close': 'Cerca',
  'last_projected_weight': 'Último Peso Previsto',
  'actions': 'Comportamiento',
  'predicted_weight': 'Peso Previsto',
  'select_a_date': 'Seleccione una Fecha',
  'days': 'días',
  'production_days': 'Dias en produccion',
  'weeks': 'semanas',
  'production_weeks': 'Semanas en producción',
  'production_time': 'Tiempo en Produccion',
  'prediction': 'Pronóstico',
  'predictions': 'Pronósticos',
  'fwu': 'P.P.A',
  'search': 'Buscar',
  'broiler_chicken': 'Pollos de Engorde',
  'headquarters': 'sede',
  'home': 'Comienzo',
  'status': 'Status',
  'silo': 'Silo',
  'ration': 'Parte',
  'capacity': 'Capacidade',
  'free': 'Libre',
  'available': 'Disponible',
  'occupation': 'Ocupación',
  'expected_end_date': 'Fecha de Finalización Prevista',
  'full': 'Completo',
  'low': 'Bajo',
  'satisfactory': 'Satisfactorio',
  'last_ration_delivered': 'Última Ración Entregada',
  'ration_delivered': 'Ración Entregada',
  'consumed': 'Consumado',
  'pre_initial': 'Pre-Inicial',
  'initial': 'Inicial',
  'growth': 'Crecimiento',
  'general': 'General',
  'male': 'Masculino',
  'female': 'Femenino',
  'production': 'Producción',
  'hatch': 'Eclosionar',
  'production_type': 'Tipo de Producción',
  'aerosacculitis': 'Aerosaculitis',
  'contaminated': 'Pájaros Condenados',
  'total_delivered_ration': 'Total Entregada',
  'total_consumed': 'Consumo Total',
  'core': 'Centro',
  'pcp': 'Planificación PCP',
  'pcp_filter': 'PCP',
  'dwg_filter': 'APD',
  'weighing': 'Peso',
  'StorageCapacity': 'Capacidad de almacenamiento',
  'TotalFeedInTheField': 'Alimentación total en el campo',
  'average_consumption_per_annimal': 'Consumo promedio por individuo',
  'TotalFree': 'Totalmente gratis',
  'nutritionTotals': 'Totais',
  'total_free': 'Total disponible (kg)',
  'week' : 'Semana',
  'age_range': 'Rango de edad',
  'total_delivered': 'Total entregado',

};
