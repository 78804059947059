<template>
  <div @mouseover="tooltip = true" @mouseleave="tooltip = false" class="relative">
    <slot></slot>
    <Transition name="slide-fade">
      <div class="w-auto absolute -right-1 tooltip transform translate-x-full whitespace-nowrap"
        v-show="tooltip">
        <div class="p-2 bg-gray-600 text-white text-sm text-center rounded shadow">
          {{ label }}
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  name: 'TooltipText',
  props: {
    label: { type: String, required: true },
  },
  data() {
    return {
      tooltip: false,
    };
  },
};
</script>

<style>
.tooltip {
  top: 50%;
  transform: translate(100%, -50%);
}

.slide-fade-enter-active {
  animation: slide-fade 0.5s;
}
.slide-fade-leave-active {
  animation: slide-fade 0.5s reverse;
}
@keyframes slide-fade {
  0% {
    opacity: 0;
    right: 0rem;
  }
  100% {
    opacity: 1;
    right: -0.25rem;
  }
}
</style>
