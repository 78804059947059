export const mutations = {
  SET_MODULE_OPENED(state, value) {
    state.moduleOpened = value;

    state.internalMenuSelected = state.routes.filter(menu => {
      return menu.label == value && menu.internal != null
    })[0];
  },

  SET_INTERNAL_MENU_SELECTED(state) {
    state.internalMenuSelected = state.routes.filter(menu => {
      return menu.label == state.moduleOpened && menu.internal != null
    })[0];
  },

  SET_ROUTES(state, value) {
    state.routes = value;
  },

  SET_IS_EXPANDED(state, value) {
    state.isExpanded = value;
  },

  SET_ROLES(state, defaultRoles) {
    state.defaultRoles = defaultRoles;
  },

  SET_DASHBOARD_ROUTES(state, value) {
    state.dashboardRoutes = value;
  },

  SET_MATRIX_DASHBOARD_ROUTES(state, value) {
    state.matrixDashboardRoutes = value;
  },
};
