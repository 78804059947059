export const getters = {
  getComfortValues(state) {
    return state.comfortValues;
  },

  getComfortIsLoaded(state) {
    return state.comfortIsLoaded;
  },

  getComfortLabel(state) {
    return state.comfortLabel;
  },

  getComfortBadgeClass(state) {
    return state.comfortBadgeClass;
  },

  getComfortCardClass(state) {
    return state.comfortCardClass;
  },

  getComfortAliasNumberClass(state) {
    return state.comfortAliasNumberClass;
  },

  getComfortIcon(state) {
    return state.comfortIcon;
  },

  getComfortCardIcon(state) {
    return state.comfortCardIcon;
  },
};
