<template>
  <div class="flex flex-row justify-between bg-white drop-shadow z-50">
    <div class="flex flex-row justify-center items-center h-16 px-3">
      <img src="@/assets/trinovati-only-logo.png" alt="Trinovati Logo" class="w-14" />
      <h1 class="text-lg ml-2 uppercase text-primary-700 hidden sm:block">Dashboard</h1>
    </div>
    <div class="border-t border-slate-200">
      <nav class="h-full flex items-center">
        <ul class="flex flex-row items-center">
          <DropdownMenu class="w-full dropdown-md-screen" right menuStyle="left-1/2 transform -translate-x-1/2 top-full mt-2">
            <template v-slot:menu-dropdown>
              <NavOption
                icon="fa-solid fa-fw fa-chart-line"
                text="performance"
                :active="$route.name == 'Home' || $route.name == 'SlaughterPrediction'"
                :expanded="false"
                :small="true"
                class="mx-2">
              </NavOption>
            </template>
            <template v-slot:menu-dropdown-items="{ toggleDropdown }">
              <router-link to="/">
                <div @click="toggleDropdown(false)">
                  <NavOption
                    icon="fa-solid fa-chart-line"
                    text="performance"
                    expanded
                    small
                    class="dropdown-item">
                  </NavOption>
                </div>
              </router-link>
              <router-link to="/slaughter-prediction">
                <div @click="toggleDropdown(false)">
                  <NavOption
                    icon="fa-solid fa-table-list"
                    text="slaughter_forecast"
                    expanded
                    small
                    class="dropdown-item">
                  </NavOption>
                </div>
              </router-link>
            </template>
          </DropdownMenu>
          <router-link to="/" class="mx-2">
            <NavOption
              icon="fa-solid fa-utensils"
              text="nutrition"
              tooltipLabel="nutrition"
              :active="$route.name == 'Nutrition'"
              :expanded="false"
              :small="true"
              :tooltip="false">
            </NavOption>
          </router-link>
          <router-link to="/" class="mx-2">
            <NavOption
              icon="fa-solid fa-briefcase-medical"
              text="sanity"
              tooltipLabel="sanity"
              :active="$route.name == 'Sanity'"
              :expanded="false"
              :small="true"
              :tooltip="false" />
          </router-link>
          <LanguageDropdown :expanded="false" header class="mx-2"></LanguageDropdown>
          <a @click.prevent="logout" class="mx-2">
            <NavOption
              icon="fas fa-sign-out-alt"
              text="Sair"
              :expanded="false"
              :small="true"
              :tooltip="false" />
          </a>
        </ul>
      </nav>
    </div>
    <slot name="expanded-button"></slot>
  </div>
</template>

<script>
import NavOption from '@/components/ui/NavOption.vue';
import LanguageDropdown from '@/components/ui/LanguageDropdown.vue';
import DropdownMenu from '@/components/ui/DropdownMenu.vue';
import { mapActions } from 'vuex';

export default {
  name: 'HeaderPage',
  components: { NavOption, LanguageDropdown, DropdownMenu },
  methods: {
    ...mapActions('environment', ['resetPropertiesValues']),
    ...mapActions('comfort', ['resetEnthalpyValues']),
    ...mapActions('user', ['resetUserValues']),
    ...mapActions('nutrition', ['resetNutritionValues']),
    logout() {
      this.resetPropertiesValues();
      this.resetEnthalpyValues();
      this.resetNutritionValues();
      this.resetUserValues();
      this.$session.destroy();
      this.$router.push('/login');
    },
  },
};
</script>
