export const mutations = {
  SET_ALERTS(state, value) {
    state.alerts = value;
  },
  SET_SHOW_ALERT_CARDS(state, value) {
    state.showAlertCards = value;
  },
  SET_ALERT_CARDS(state, value) {
    state.alertCards = value;
  },
  SET_TOTAL_ALERTS(state, value) {
    state.totalAlerts = value;
  },
  SET_ALERTS_IS_LOADED(state, value) {
    state.alertIsLoaded = value;
  },
  SET_ALERTS_HISTORY_IS_LOADED(state, value) {
    state.alertHistoryIsLoaded = value;
  },
  SET_TOTAL_ALERTS_HISTORY(state, value) {
    state.totalAlertsHistory = value;
  },
  SET_ALERTS_HISTORY(state, value) {
    state.alertsHistory = value;
  },
};