<template>
  <div class="relative inline-block">
    <div @click="openDropdown = !openDropdown">
      <slot name="menu-dropdown"></slot>
    </div>
    <transition :name="left ? 'animation-dropdown-left' : 'animation-dropdown-right'">
      <!-- :class="[
        menuStyle,
        { 'dropdown-right': right },
        { 'dropdown-left': left },
        { 'dropdown-top': top },
        { 'dropdown-bottom': bottom }
      ]" -->
      <div v-if="openDropdown" class="absolute z-50 flex shrink"
        :class="menuStyle">
        <div class="px-2 pt-2 pb-2 bg-white rounded-md box-shadow">
          <div class="flex flex-col">
            <slot name="menu-dropdown-items" :openDropdown="openDropdown" :toggleDropdown="toggleDropdown">
            </slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'DropdownMenu',
  props: {
    menuStyle: { type: String },
    right: { type: Boolean, default: false },
    left: { type: Boolean, default: false },
    top: { type: Boolean, default: false },
    bottom: { type: Boolean, default: false },
  },
  data() {
    return {
      openDropdown: false,
    };
  },
  methods: {
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.openDropdown = false;
      }
    },
    toggleDropdown(value) {
      this.openDropdown = value;
    },
  },
  mounted() {
    document.addEventListener('click', this.close);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close);
  },
};
</script>

<style lang="postcss">
  .dropdown-item {
    @apply flex flex-row items-start bg-transparent p-2 hover:bg-gray-200 transition cursor-pointer px-2 py-1 rounded text-xs sm:text-sm font-bold duration-300 ease-out select-none text-slate-600;
  }

  .animation-dropdown-right-enter-active {
    transition: all 0.3s ease;
  }
  .animation-dropdown-right-leave-active {
    transition: all 0.3s ease;
  }
  .animation-dropdown-right-enter,
  .animation-dropdown-right-leave-to {
    transform: translateX(10px) translateY(-50%);
    opacity: 0;
  }
  @media (max-width: 768px) {
    .dropdown-md-screen .animation-dropdown-right-enter,
    .dropdown-md-screen .animation-dropdown-right-leave-to {
      transform: translateX(-50%) translateY(10px);
      opacity: 0;
    }
  }

  .animation-dropdown-left-enter-active {
    transition: all 0.3s ease;
  }
  .animation-dropdown-left-leave-active {
    transition: all 0.3s ease;
  }
  .animation-dropdown-left-enter,
  .animation-dropdown-left-leave-to {
    transform: translateX(-50px) translateY(10px);
    opacity: 0;
  }
  @media (max-width: 768px) {
    .dropdown-md-screen .animation-dropdown-left-enter,
    .dropdown-md-screen .animation-dropdown-left-leave-to {
      transform: translateY(-50%);
      opacity: 0;
    }
  }

  .dropdown-right {
    @apply left-full ml-2;
  }
  .dropdown-left {
    @apply right-full mr-2;
  }
</style>
