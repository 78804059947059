<template>
  <div>
    <div class="bg-white rounded my-2 p-2 drop-shadow-sm">
      <div class="mb-1">
        <span class="select-none font-bold text-gray-400 text-sm">{{ $t('filtros') }}</span>
      </div>

      <div class="flex justify-between">
        <div>
          <!-- Configured Filters -->
          <div class="flex flex-row flex-wrap">
            <div v-for="(filter, index) in getConfiguredFilters" :key="index"
              class="flex flex-row mr-1 first:ml-0 last:mr-0">
              <FilterOption v-if="filter.field != 'priority'" :name="filter.keyLabel"
                :value="filter.optionLabel || filter.value"
                @click.native="selectOption(filter.field, filter.value, filter.operator)" />
            </div>
          </div>

          <!-- Filter Buttons -->
          <div class="flex flex-row flex-wrap -mb-1">
            <div v-for="(filter, index) in filters" :key="index" class="mr-1 mb-1 first:ml-0 last:mr-0">

              <div v-if="isValidFilter(filter) && filter.mode != 'Selectable'" @click="() => {
                if (!(getIsGuest && filter.key == 'name')) {
                  selectFilter(filter);
                }
              }" :class="[
                filterIsSelected(filter) ? 'filter-button-selected' : 'filter-button',
                { 'disabled-filter': (getIsGuest && filter.key == 'name') }
              ]">
                {{ $t(filter.text) }}
              </div>
              <div v-if="isValidFilter(filter) && filter.mode == 'Selectable'" @click="() => {
                if (!(getIsGuest && filter.key == 'name')) {
                  selectFilter(filter);
                }
                selectOption(
                  filter.key,
                  filter.label,
                  'isExactly',
                  (keyLabel = filter.label),
                )
              }" :class="[
                filterIsSelected(filter) ? 'filter-button-selected' : 'filter-button',
                { 'disabled-filter': (getIsGuest && filter.key == 'name') }
              ]">
                {{ $t(filter.text) }}
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-0" v-if="this.state == 'nutrition' && getIsLayingBirds">
          <div class="button !w-auto !py-0 !px-2 ml-2 filter-button" @click="filterProductionType('7')">
            {{ $t('production_birds') }}
          </div>
          <div class="button !w-auto !py-0 !px-2 ml-2 filter-button" @click="filterProductionType('6')">
            {{ $t('breeding_birds') }}
          </div>
        </div>
      </div>

    </div>

    <!-- Filter Container -->
    <transition name="animation-filter">
      <div v-if="(selectedFilter !== null && selectedFilter.mode !== 'Selectable')">
        <div class="bg-white rounded my-2 p-2 drop-shadow-sm transition" v-for="(filter, i) in filters" :key="i"
          v-show="filter == selectedFilter">
          <!-- Button Filter -->
          <div class="flex flex-col md:flex-row" v-if="filter.mode == 'Button'">
            <div v-for="(option, j) in filter.options" :key="j" @click="
              selectOption(
                filter.key,
                option.value,
                'isEqual',
                (keyLabel = filter.label),
                (optionLabel = option.text)
              )
              " class="md:mx-1 md:my-0 my-1 first:mt-0 first:ml-0 last:mb-0 last:mr-0" :class="findFilter({ field: filter.key, value: option.value, operator: 'isEqual' }) >= 0
                ? 'filter-button-selected'
                : 'filter-button'
                ">
              {{ $t(option.text) }}
            </div>
          </div>

          <!-- Text Filter -->
          <div class="flex flex-row" v-else-if="filter.mode == 'Text'">
            <input type="text" class="custom-input input-sm" @keyup.enter="
              selectOption(filter.key, auxTextValue, 'includesText', (keyLabel = filter.label))
              " v-model="auxTextValue" />
            <button @click="
              selectOption(filter.key, auxTextValue, 'includesText', (keyLabel = filter.label))
              " class="ml-2 filter-button" v-html="findFilter({ field: filter.key, value: auxTextValue, operator: 'includesText' }) >=
                0
                ? $t('remove')
                : $t('add')
                "></button>
          </div>

          <!-- Number Filter -->
          <div class="flex flex-col md:flex-row" v-else-if="filter.mode == 'Number'">
            <div class="flex flex-col md:flex-row">
              <button class="filter-button mb-1 md:mb-0 md:mr-1 whitespace-nowrap w-full md:w-auto"
                :class="[numberOptionSelected == 0 ? 'filter-button-selected' : 'filter-button']"
                @click="numberOptionSelected = 0">
                {{ $t('greater_than') }}
              </button>
              <button class="filter-button mb-1 md:mb-0 md:mr-1 whitespace-nowrap"
                :class="[numberOptionSelected == 1 ? 'filter-button-selected' : 'filter-button']"
                @click="numberOptionSelected = 1">
                {{ $t('less_than') }}
              </button>
              <button v-if="filter.key == 'age'" class="mb-1 md:mb-0 md:mr-2 whitespace-nowrap"
                :class="[numberOptionSelected == 2 ? 'filter-button-selected' : 'filter-button']"
                @click="numberOptionSelected = 2">
                {{ $t('exactly') }}
              </button>
            </div>
            <input type="number" class="custom-input input-sm mb-1 md:mb-0" @keyup.enter="
              selectOption(
                filter.key,
                auxNumberValue,
                numberOptions[numberOptionSelected],
                (keyLabel = filter.label),
                (optionLabel = `${numberOptionsLabel[numberOptionSelected]} ${auxNumberValue}`)
              )
              " v-model="auxNumberValue" />
            <button @click="
              selectOption(
                filter.key,
                auxNumberValue,
                numberOptions[numberOptionSelected],
                (keyLabel = filter.label),
                (optionLabel = `${numberOptionsLabel[numberOptionSelected]} ${auxNumberValue}`)
              )
              " class="filter-button md:ml-2 whitespace-nowrap" v-html="findFilter({
                field: filter.key,
                value: auxNumberValue,
                operator: numberOptions[numberOptionSelected],
              }) >= 0
                ? $t('remove')
                : $t('add')
                ">
            </button>
          </div>

          <div class="flex flex-col md:flex-row" v-else-if="filter.mode == 'predicted_weight'">
            <div class="flex">
              <DropdownMenu right class="w-full dropdown-md-screen select-none"
                menuStyle="transform md:top-1/2 md:-translate-y-1/2 md:-translate-x-0 md:left-full md:ml-2 left-1/2 -translate-x-1/2 mt-2 top-full">
                <template v-slot:menu-dropdown>
                  <button class="filter-button mb-1 md:mb-0 md:mr-1 whitespace-nowrap w-full md:w-auto">
                    <font-awesome-icon icon="fa-solid fa-calendar-days" />
                    <span class="ml-1">{{ $t('date') }}:</span>
                    <span>
                      {{ formattedDateValue ? formattedDateValue : $t('select_a_date') }}
                    </span>
                  </button>
                </template>
                <template v-slot:menu-dropdown-items>
                  <div>
                    <v-app>
                      <v-date-picker v-model="auxDateValue" no-title scrollable color="red">
                      </v-date-picker>
                    </v-app>
                  </div>
                </template>
              </DropdownMenu>
            </div>
            <div class="flex flex-col md:flex-row">
              <button class="filter-button mb-1 md:mb-0 md:mr-1 whitespace-nowrap w-full md:w-auto"
                :class="[numberOptionSelected == 0 ? 'filter-button-selected' : 'filter-button']"
                @click="numberOptionSelected = 0">
                {{ $t('greater_than') }}
              </button>
              <button class="filter-button mb-1 md:mb-0 md:mr-1 whitespace-nowrap"
                :class="[numberOptionSelected == 1 ? 'filter-button-selected' : 'filter-button']"
                @click="numberOptionSelected = 1">
                {{ $t('less_than') }}
              </button>
              <button class="mb-1 md:mb-0 md:mr-2 whitespace-nowrap"
                :class="[numberOptionSelected == 2 ? 'filter-button-selected' : 'filter-button']"
                @click="numberOptionSelected = 2">
                {{ $t('exactly') }}
              </button>
            </div>
            <input type="number" class="custom-input input-sm mb-1 md:mb-0" @keyup.enter="
              selectOption(
                filter.key,
                [auxDateValue, auxNumberValue],
                ['isExactly', numberOptions[numberOptionSelected]],
                (keyLabel = filter.label),
                (optionLabel = [
                  `Data ${numberOptionsLabel[2]} ${auxDateValue}`, `Peso ${numberOptionsLabel[numberOptionSelected]} ${auxNumberValue}`
                ])
              )
              " v-model="auxNumberValue" />
            <button @click="
              selectOption(
                filter.key,
                [auxDateValue, auxNumberValue],
                ['isExactly', numberOptions[numberOptionSelected]],
                (keyLabel = filter.label),
                (optionLabel = [
                  `Data ${numberOptionsLabel[2]} ${formattedDateValue}`, `Peso ${numberOptionsLabel[numberOptionSelected]} ${auxNumberValue}`
                ])
              )
              " class="filter-button md:ml-2 whitespace-nowrap" v-html="findFilter({
                field: filter.key,
                value: auxNumberValue,
                operator: numberOptions[numberOptionSelected],
              }) >= 0
                ? $t('remove')
                : $t('add')
                ">
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import FilterOption from '@/components/ui/FilterOption.vue';
import DropdownMenu from '@/components/ui/DropdownMenu.vue';

import { isNotNull } from '@/utils';
import { parseISO, format } from 'date-fns';

export default {
  name: 'PropertyFilterMenu',
  components: { FilterOption, DropdownMenu },
  props: {
    slaughterPrediction: { type: Boolean, default: false },
    state: {
      type: String,
      default: 'performance',
      validator(value) {
        return ['performance', 'slaughter-prediction', 'sanity', 'nutrition', 'pcp'].includes(value);
      },
    },
  },
  data() {
    return {
      selectedFilter: null,

      auxDateValue: new Date().toISOString().split('T')[0],
      auxTextValue: '',
      auxNumberValue: '',
      renderButtons: 0,

      numberOptionSelected: 0,
      numberOptions: {
        0: 'isBigger',
        1: 'isSmaller',
        2: 'isExactly',
      },
      numberOptionsLabel: {
        0: this.$t('greater_than'),
        1: this.$t('less_than'),
        2: this.$t('exactly'),
      },

      validFilters: ['Text', 'Number', 'Button', 'Selectable', 'predicted_weight'],
    };
  },
  computed: {
    ...mapGetters('environmentFilter', [
      'getFilters',
      'getIsLayingBirds',
      'getMatrixFilters',
      'getLayingBirdsFilters',
      'getSlaughterPredictionFilters',
      'getSanityFilters',
      'getNutritionFilters',
      'getConfiguredFilters',
      'getIsMatrix',
      'getPcpFilters'
    ]),
    ...mapGetters('user', ['getIsGuest']),
    formattedDateValue() {
      let date = parseISO(this.auxDateValue);
      return format(new Date(date), 'dd/MM/yyyy');
    },
    filters() {
      if (this.state == 'slaughter-prediction') {
        return this.getSlaughterPredictionFilters;
      } else if (this.state == 'pcp') {
        return this.getPcpFilters;
      } else if (this.state == 'sanity') {
        return this.getSanityFilters;
      } else if (this.state == 'nutrition') {
        return this.getNutritionFilters;
      } else if (this.getIsMatrix) {
        return this.getMatrixFilters
      } else if (this.isLayingBirds) {
        return this.getLayingBirdsFilters;
      }
      return this.getFilters;
    },
    notSelectableFilters() {
      return this.filters.filter(function (u) {
        return u.mode != "Selectable"
      })
    },
    SelectableFilters() {
      return this.filters.filter(function (u) {
        return u.mode = "Selectable"
      })
    },
    selectableLabel() {
      return this.getConfiguredFilters.filter(function (u) {
        return u
      })
    },
  },
  methods: {
    ...mapActions('environmentFilter', ['setFilteringValue', 'removeFilter', 'addFilter', 'toggleParam', 'resolveParams']),
    ...mapGetters('environment', ['getShedsIsLoaded']),
    ...mapGetters('nutrition', ['getNutritionIsLoaded']),
    ...mapGetters('environmentFilter', ['getRequestParams']),
    isValidFilter(filter) {
      try {
        if (filter?.mode != null) {
          if (this.validFilters.includes(filter.mode)) {
            if (filter.mode == 'Button') {
              if (filter.options != null && filter.options.length > 0) {
                return true;
              }
              return false;
            } else {
              return true;
            }
          }
          return false;
        }
        return false;
      } catch (err) {
        return false;
      }
    },
    filterIsSelected(filter) {
      return this.selectedFilter !== null && this.selectedFilter.key == filter.key;
    },
    findFilter(filter, index = null) {
      let filters = this.getConfiguredFilters || [];
      if (isNotNull(index)) {
        return filters.findIndex((f) => (f.index = index));
      }
      return filters.findIndex((f) => {
        return f.field == filter.field && f.value == filter.value && f.operator == filter.operator;
      });
    },
    clearAuxVariables() {
      this.auxTextValue = '';
      this.auxNumberValue = '';
    },
    selectFilter(filter) {
      if (this.selectedFilter == null) {
        this.selectedFilter = filter;
      } else if (this.selectedFilter.key === filter.key) {
        this.selectedFilter = null;
      } else {
        this.selectedFilter = filter;
      }
      this.clearAuxVariables();
    },
    async filterProductionType(production_type__external_id) {
      this.toggleParam({
        production_type__external_id,
      });
      await this.resolveParams();
    },
    selectOption(field, value, operator, keyLabel = null, optionLabel = null, index = null) {
      if (value === null || value === '') {
        return;
      } else if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          if (value[i] === null || value[i] === '') {
            return;
          }
        }
      }
      if (this.getShedsIsLoaded() || this.getNutritionIsLoaded()) {
        let finded = this.findFilter({ field, operator, value }, index);
        if (finded >= 0) {
          this.removeFilter(finded);
        } else {
          this.addFilter({
            field,
            operator,
            value,
            keyLabel,
            optionLabel,
            conjunction: false,
            filtered: false,
            stage: this.state
          });
        }
        this.renderButtons++;
      }
    },
  },
};
</script>

<style lang="postcss">
.filter-button {
  @apply px-2 py-1 rounded text-xs sm:text-sm font-bold cursor-pointer duration-300 ease-out select-none text-slate-800 bg-gray-200 hover:bg-gray-300;
}

.filter-button-selected {
  @apply px-2 py-1 rounded text-xs sm:text-sm font-bold cursor-pointer duration-300 ease-out select-none bg-primary-700 text-white hover:bg-primary-800;
}

.toggle-button-selected {
  @apply px-2 py-1 rounded text-xs sm:text-sm font-bold cursor-pointer duration-300 ease-out select-none bg-slate-700 text-white hover:bg-slate-800;
}

.filter-item {
  @apply mx-1;
}

.disabled-filter {
  @apply bg-gray-400 cursor-not-allowed hover:bg-gray-400;
}

.filter-condition {
  @apply rounded text-xs sm:text-sm px-2 cursor-pointer mb-1 transition flex items-center select-none;
}

.filter-condition {
  padding-top: 0.1rem;
}

.filter-condition {
  padding-bottom: 0.1rem;
}

.filter-conjunction {
  @apply bg-slate-700 text-white hover:bg-slate-800;
}

.filter-disjunction {
  @apply bg-primary-700 text-white hover:bg-primary-800;
}

.animation-filter-enter-active {
  transition: all 0.3s ease;
  max-height: 172px;
}

.animation-filter-leave-active {
  transition: all 0.3s ease;
  max-height: 172px;
}

.animation-filter-enter,
.animation-filter-leave-to {
  transform: translateX(10px);
  opacity: 0;
  max-height: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
