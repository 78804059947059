export const state = {
  filters: [
    {
      key: 'producer',
      text: 'producer',
      mode: 'Text',
      label: 'producer',
    },
    {
      key: 'lineage',
      text: 'lineage',
      mode: 'Text',
      label: 'lineage',
    },
    {
      key: 'sex',
      text: 'sex',
      mode: 'Text',
      label: 'sex'
    },
    {
      key: 'age',
      text: 'age',
      mode: 'Number',
      label: 'age',
    },
    {
      key: 'weight',
      text: 'weight',
      mode: 'Number',
      label: 'weight',
    },
    {
      key: 'variation_coef',
      text: 'uniformity',
      mode: 'Number',
      label: 'uniformity',
    },
    {
      key: 'performance',
      text: 'performance',
      mode: 'Number',
      label: 'performance',
    },
  ],
  matrixFilters: [
    {
      key: 'producer',
      text: 'producer',
      mode: 'Text',
      label: 'producer',
    },
    {
      key: 'lineage',
      text: 'lineage',
      mode: 'Text',
      label: 'lineage',
    },
    {
      key: 'sex',
      text: 'sex',
      mode: 'Text',
      label: 'sex'
    },
    {
      key: 'age',
      text: 'batch_age',
      mode: 'Number',
      label: 'age',
    },
  ],
  slaughterPredictionFilters: [
    {
      key: 'producer',
      text: 'producer',
      mode: 'Text',
      label: 'producer',
    },
    {
      key: 'age',
      text: 'age',
      mode: 'Number',
      label: 'age',
    },
    {
      key: 'weight',
      text: 'weight',
      mode: 'Number',
      label: 'weight',
    },
    {
      key: 'performance',
      text: 'performance',
      mode: 'Number',
      label: 'performance',
    },
    {
      key: ['predicted_weight_date', 'predicted_weight'],
      text: 'predicted_weight',
      mode: 'predicted_weight',
      modes: ['Date', 'Number'],
      label: 'predicted_weight',
    },
  ],
  pcpFilters: [
    {
      key: 'producer',
      text: 'producer',
      mode: 'Text',
      label: 'producer',
    },
    {
      key: 'age',
      text: 'age',
      mode: 'Number',
      label: 'age',
    },
    {
      key: 'weight',
      text: 'weight',
      mode: 'Number',
      label: 'weight',
    },
    {
      key: 'performance',
      text: 'performance',
      mode: 'Number',
      label: 'performance',
    },
  ],
  pcpPlanningFilters: [
    {
      key: 'producer',
      text: 'producer',
      mode: 'Text',
      label: 'producer',
    },
    {
      key: 'age',
      text: 'age',
      mode: 'Number',
      label: 'age',
    },
    {
      key: 'weight',
      text: 'weight',
      mode: 'Number',
      label: 'weight',
    },
    {
      key: 'performance',
      text: 'performance',
      mode: 'Number',
      label: 'performance',
    },
    {
      key: 'target_date',
      text: 'target_date',
      mode: 'Date',
      label: 'target_date',
    },
    {
      key: 'target_weight',
      text: 'target_weight',
      mode: 'Text',
      label: 'target_weight',
    },
  ],
  sanityFilters: [
    {
      key: 'producer',
      text: 'producer',
      mode: 'Text',
      label: 'producer',
    },
    {
      key: 'lineage',
      text: 'lineage',
      mode: 'Text',
      label: 'lineage',
    },
  ],
  alertFilters: [
    {
      key: 'shed',
      text: 'shed',
      mode: 'Text',
      label: 'shed',
    },
    {
      key: 'type',
      text: 'type',
      mode: 'Button',
      options: [
        { text: 'ambience', value: 'Ambiência' },
        { text: 'sanity', value: 'Sanidade' },
        { text: 'performance', value: 'Desempenho' },
        { text: 'nutrition', value: 'Nutrição' },
      ],
      label: 'type',
    },
    {
      key: 'subtype',
      text: 'subtype',
      mode: 'Button',
      options: [
        { text: 'CO2', value: 'Co2' },
        { text: 'bed_temperature', value: 'Temperatura da Cama' },
        { text: 'water_temperature', value: 'Temperatura da Água' },
        { text: 'ambient-humidity', value: 'Umidade Ambiente' },
        { text: 'ambient-temperature', value: 'Temperatura Ambiente' },
        { text: 'humidity-ambient-temperature', value: 'Temperatura e Umidade Ambiente'},
        { text: 'aerosacculitis', value: 'Aerossaculite'},
        { text: 'weighing', value: 'Pesagem'},
        { text: 'dwg_filter', value: 'GPD'},
        { text: 'pcp_filter', value: 'PCP'},
      ],
      label: 'subtype',
    },
    {
      key: 'age',
      text: 'age',
      mode: 'Number',
      label: 'age',
    },
  ],
  alertsHistoryFilters: [
    {
      key: 'shed',
      text: 'shed',
      mode: 'Text',
      label: 'shed',
    },
  ],
  nutritionFilters: [
    {
      key: 'producer',
      text: 'producer',
      mode: 'Text',
      label: 'producer',
    },
    {
      key: 'filled_area_percentage',
      text: 'occupation',
      mode: 'Number',
      label: 'occupation',
    },
    {
      key: 'status',
      text: 'status',
      mode: 'Button',
      options: [
        { text: 'critical', value: 'critical' },
        { text: 'low', value: 'low' },
        { text: 'satisfactory', value: 'satisfactory' },
      ],
      label: 'status',
    },
  ],
  searchOptions: [
    {
      key: 'predict_age',
      text: 'age',
      mode: 'Number',
      onlyIsExactly: true,
      label: 'age',
    },
    {
      key: 'adjusted_date',
      text: 'date',
      mode: 'Date',
      label: 'date',
    },
    {
      key: 'predict_weight',
      text: 'weight',
      mode: 'Number',
      label: 'weight',
    },
  ],
  searchPcpOptions: [
    {
      key: 'adjusted_date',
      text: 'date',
      mode: 'Date',
      label: 'date',
    },
    {
      key: 'predict_weight',
      text: 'weight',
      mode: 'Number',
      label: 'weight',
    },
  ],
  configuredFilters: null,
  pcpConfiguredFilters: null,
  pcpPlanningConfiguredFilters: null,
  indexFilter: 0,
  pcpIndexFilter: 0,
  showDisabledProperties: false,
  showSlaughterState: false,
  showLodgeState: true,
  showPreLodgeState: false,
  showPriorityState: false,
  isMatrix: false,
  isLayingBirds: false,
  isHatch: false,
  requestParams: {
    stage: [],
    target_weight: [],
    enthalpy: [],
    producer: [],
    name: [],
    storage: [],
    lineage: [],
    sex: [],
    type: [],
    subtype: [],
    age: [],
    weight: [],
    variation_coef: [],
    performance: [],
    priority: false,
    predicted_weight: [],
    predicted_weight_date: [],
    filled_area_percentage: [],
    status: [],
    ration: [],
    shed: [],
    matrix: false,
    layingBirds: false,
    target_date: []
  },
  flagSlaughterPrediction: false,
  flagPcpPrediction: false,
  flagPcpSimulation: false,
  searchSlaughterPrediction: null,
  searchPcpPrediction: null,
  params: {}
};
