import { SiloGroup } from "@/models/SiloGroup";
import { getSilosList, getSilosShed, getRationTypes, getFeedControls, postFeedControl, deleteFeedControl, putFeedControl, getSiloOccupationHistory, getSilosConsumptionHistory } from "@/services/nutrition";
import i18n from '@/plugins/i18n';

export const actions = {
  async sendFeedControl({ rootGetters }, data) {
    const token = rootGetters['user/getToken'];
    await postFeedControl(token.access, data);
  },
  async loadSilosConsumptionHistory({ rootGetters, commit }, siloArray = []) {
    const token = rootGetters['user/getToken'];
    const histories = await Promise.all(siloArray.map(silo => getSilosConsumptionHistory(token.access, silo.id, silo.params).catch(() => [])));
    siloArray?.forEach(silo => {
      if (silo.type === 'all') {
        commit('SET_SILOS_CONSUMPTION_HISTORY_TOTAL', { id: silo.id, type: 'total', data: histories[0]?.data || [] });
        commit('SET_SILOS_CONSUMPTION_HISTORY_AVERAGE_CONSUMPTION', { id: silo.id, type: 'average_consumption', data: histories[0]?.data || [] });
      } else if(silo.type === 'total') {
        commit('SET_SILOS_CONSUMPTION_HISTORY_TOTAL', { id: silo.id, type: 'total', data: histories[siloArray.findIndex(i => i.type === 'total')]?.data || [] });
      } else {
        commit('SET_SILOS_CONSUMPTION_HISTORY_AVERAGE_CONSUMPTION', { id: silo.id, type: 'average_consumption', data: histories[siloArray.findIndex(i => i.type === 'average_consumption')]?.data || [] });
      }
    })
  },
  async removeFeedControl({ rootGetters }, data) {
    const token = rootGetters['user/getToken'];
    await deleteFeedControl(token.access, data);
  },
  async editFeedControl({ rootGetters }, data) {
    const token = rootGetters['user/getToken'];
    await putFeedControl(token.access, data.id, data);
  },
  async loadSilosShed({ rootGetters, commit }, id) {
    const token = rootGetters['user/getToken'];
    const silosShed = await getSilosShed(token.access, id);
    commit('SET_SILOS_SHED_ADD_RATION', silosShed)
  },
  async loadRationTypes({ rootGetters, commit }, id) {
    const token = rootGetters['user/getToken'];
    const rationTypes = await getRationTypes(token.access, id);
    commit('SET_RATION_TYPES_ADD_RATION', rationTypes)
  },
  async loadFeedControls({ rootGetters, commit }, { id, params }) {
    const token = rootGetters['user/getToken'];
    const feedControls = await getFeedControls(token.access, id, params);
    commit('SET_FEED_CONTROLS', feedControls)
  },
  async loadSiloOccupationHistory({ rootGetters, commit }, siloArray = []) {
    const token = rootGetters['user/getToken'];
    const histories = await Promise.all(siloArray.map(silo => getSiloOccupationHistory(token.access, silo.id, silo.params).catch(() => [])));
    commit('SET_SILO_OCCUPATION_HISTORIES', siloArray?.map(silo => ({ id: silo.id, data: histories?.find(history => history.id === silo.id)?.data || [] })))
  },
  async loadSilos({ rootGetters, commit }, { params, page, itemsPerPage }) {
    this.resetNutritionValues;
    const token = rootGetters['user/getToken'];
    const allEqual = arr => arr.every(v => v === arr[0])
    let silos = [];
    let showCards = false;
    let rationCards = [];
    let companyId = "";
    let companyRationTypes = {}
    let response = await getSilosList(token.access, itemsPerPage, (page - 1) * itemsPerPage, params);

    if (response?.results?.length) {
      await response.results.map(element => {
        let silo = new SiloGroup(element);
        silos.push(silo)
      });
    }

    if (allEqual(token.user.companies)) {
      showCards = true
      companyId = token.user.companies[0]
    }

    response?.ration_list?.forEach((ration, i) => {
      
      rationCards.push({
        'name': ration.phase,
        'phase': ration.phase,
        'count': i,
        'value': parseInt(ration.available_weight).toLocaleString(i18n.locale)
      })
    })
    commit('SET_SILOS', silos);
    commit('SET_RATION_TYPES', companyRationTypes);
    commit('SET_SHOW_NUTRITION_CARDS', showCards && Boolean(rationCards.length));
    commit('SET_SHOW_NUTRITION_TOTALS', showCards);
    commit('SET_NUTRITION_VALUES', rationCards);
    commit('SET_TOTAL_NUTRITION', rationCards.length);
    commit('SET_TOTAL_SILOS', response.count);
    commit('SET_NUTRITION_IS_LOADED', true);
  },
  async setTotalNutritionValues({ rootGetters, commit }) {
    const nutritionValues = rootGetters['nutrition/getNutritionValues']

    let totalNutritionCards = nutritionValues;

    commit('SET_NUTRITION_CARDS', totalNutritionCards);
    commit('SET_TOTAL_NUTRITION', totalNutritionCards.length);
    commit('SET_NUTRITION_CARDS_IS_LOADED', true)
  },
  resetNutritionValues({ commit }) {
    commit('SET_RATION_TYPES', null);
    commit('SET_NUTRITION_VALUES', null);
    commit('SET_NUTRITION_IS_LOADED', false);
  },
  openDialog({ commit }) {
    commit('SET_DIALOG_STATE', true);
  },
  closeDialog({ commit }) {
    commit('SET_DIALOG_STATE', false);
  },
  dialogLoading({ commit }) {
    commit('SET_DIALOG_LOADING', true);
  },
  notDialogLoading({ commit }) {
    commit('SET_DIALOG_LOADING', false);
  },
}
