import Vue from 'vue';

import '@/plugins/axios';
import '@/plugins/vue-session';
import '@/plugins/fontawesome';
import '@/plugins/vue-splide';
import '@/plugins/floating-vue';

import '@/assets/styles/main.css';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import vuetify from '@/plugins/vuetify';
import i18n from '@/plugins/i18n';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
