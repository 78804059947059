<template>
  <v-tooltip top v-if="time">
    <template v-slot:activator="{ on, attrs }">
      <div
       :class="[getComfortBadgeClass[comfort] || '']"
       v-bind="attrs"
       v-on="on"
      >
        <font-awesome-icon :icon="getComfortIcon[comfort]" class="mr-2" />
        <span v-html="$t(getComfortLabel[comfort]) || ''"></span>
      </div>
    </template>
    <span>{{ timeConverted }}</span>
  </v-tooltip>
  <div v-else :class="[getComfortBadgeClass[comfort] || '']">
    <font-awesome-icon :icon="getComfortIcon[comfort]" class="mr-2" />
    <span v-html="$t(getComfortLabel[comfort]) || ''"></span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import format from 'date-fns/esm/format/index';

export default {
  name: 'ComfortBadge',
  props: {
    comfort: { type: Number, required: true },
    time: { type: String, required: false },
  },
  computed: {
    ...mapGetters('comfort', ['getComfortBadgeClass', 'getComfortLabel', 'getComfortIcon']),
    timeConverted: function() {
      return format(new Date(this.time), 'dd/MM/yyyy HH:mm');
    },
  },
};
</script>
