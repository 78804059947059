export const actions = {
  openDialog({ commit }) {
    commit('SET_DIALOG_STATE', true);
  },
  closeDialog({ commit }) {
    commit('SET_DIALOG_STATE', false);
  },
  dialogLoading({ commit }) {
    commit('SET_DIALOG_LOADING', true);
  },
  notDialogLoading({ commit }) {
    commit('SET_DIALOG_LOADING', false);
  },
};
