export const getters = {
  getAlerts(state) {
    return state.alerts;
  },

  getShowAlertCards(state) {
    return state.showAlertCards;
  },

  getAlertCards(state) {
    return state.alertCards;
  },

  getAlertCardIcon(state) {
    return state.alertCardIcon;
  },

  getAlertCardClass(state) {
    return state.alertCardClass;
  },

  getTotalAlerts(state) {
    return state.totalAlerts;
  },

  getAlertIsLoaded(state) {
    return state.alertIsLoaded;
  },

  getAlertLabel(state) {
    return state.alertLabel;
  },

  getAlertsHistory(state) {
    return state.alertsHistory;
  },

  getTotalAlertsHistory(state) {
    return state.totalAlertsHistory;
  },

  getAlertHistoryIsLoaded(state) {
    return state.alertHistoryIsLoaded;
  },
};
