import FloatingVue, { Tooltip } from 'floating-vue';
import 'floating-vue/dist/style.css';
import Vue from 'vue';

Vue.component('FTooltip', Tooltip);

Vue.use(FloatingVue, {
  themes: {
    'tooltip-right': {
      $extend: 'tooltip',
      $resetCss: true,
    },
  },
});
