import { format } from 'date-fns';


export function isNotNull (value) {
  return value !== undefined && value !== null;
}

export function isArray (value) {
  return value !== null && value !== undefined && Array.isArray(value);
}

export function isNotEmptyObject (value) {
  return (
    value !== null &&
    value !== undefined &&
    Object.keys(value).length !== 0 &&
    Object.getPrototypeOf(value) === Object.prototype
  );
}

export function isEqual (item, value) {
  if (value !== null || value !== undefined) {
    return item == value;
  }
  return false;
}

export function includesText (item, value) {
  if (value !== null || value !== undefined) {
    return item.toLowerCase().includes(value.toLowerCase());
  }
  return false;
}

export function isBigger (item, value) {
  if (value !== null || value !== undefined) {
    return item > parseFloat(value);
  }
  return false;
}

export function isSmaller (item, value) {
  if (value !== null || value !== undefined) {
    return item < parseFloat(value);
  }
  return false;
}

export function isExactly (item, value) {
  if (value !== null || value !== undefined) {
    return item == parseFloat(value);
  }
  return false;
}

export function fixedPosition(number, position) {
  if (typeof number != 'number') {
    return number;
  }
  return parseFloat(number.toFixed(position));
}

export function arraysEqual(a, b) {
  if (a === b) {
    return true;
  }

  if (a == null || b == null) {
    return false;
  }

  if (a.length !== b.length) {
    return false;
  }

  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }

  return true;
}


export function getDateFormatted(date) {
  try {
    return format(new Date(date), 'dd/MM/yyyy HH:mm');
  } catch (err) {
    return '--';
  }
}

export function capitalize(word) {
  if (!isNotNull(word)) return '--';
  return word[0].toUpperCase() + word.slice(1);
}

export function debounce(fn, wait){
  let timer;
 return function(...args){
   if(timer) {
      clearTimeout(timer);
   }
   const context = this;
   timer = setTimeout(()=>{
      fn.apply(context, args);
   }, wait);
 }
}
