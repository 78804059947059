<template>
  <div id="app" class="flex w-full" :class="{ 'flex-col': !showSideBar }" v-if="$route.meta.layout">
    <HeaderPage v-if="!showSideBar" />
    <NewSideBar v-else :expanded="getExpandvalue(expandendSideBar)" :routes="routes"
      :expandedInternalMenu="getIsExpanded">
      <template>
        <div v-if="windowWidth > 1024"
          class="hidden md:flex justify-center items-center border-t border-slate-200 h-16">
          <div class="rounded-button text-lg" @click="expandInternalMenu">
            <font-awesome-icon icon="fas fa-angle-left" v-if="expandendInternalMenu" />
            <font-awesome-icon icon="fas fa-angle-right" v-else />
          </div>
        </div>
      </template>
    </NewSideBar>
    <div class="w-full flex-grow bg-slate-100 flex flex-col overflow-x-hidden">
      <div class="px-5 py-2 flex-auto">
        <router-view :key="$route.fullPath" />
      </div>
    </div>
  </div>
  <div v-else>
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import NewSideBar from '@/components/layout/NewSideBar.vue';
import HeaderPage from '@/components/layout/HeaderPage.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  components: { HeaderPage, NewSideBar },
  data() {
    return {
      showSideBar: true,
      showNavBar: false,
      expandendSideBar: false,
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapGetters('interface', [
      'getDefaultRoutes',
      'getRoutes',
      'getDefaultDashboardRoutes',
      'getDashboardRoutes',
      'getDefaultMatrixDashboardRoutes',
      'getLayingBirdsDefaultDashboardRoutes',
      'getMatrixDashboardRoutes',
    ]),
    ...mapGetters('interface', ['getModuleOpened', 'getInternalMenuSelected', 'getIsExpanded']),
    ...mapGetters('environmentFilter', ['getIsMatrix']),
    ...mapGetters('environment', ['getSelectedShed']),
    ...mapGetters('nutrition', ['getSelectedSilo']),
    ...mapGetters('user', ['getBroilerRoles', 'getMatrixRoles']),
    routes: {
      get() {
        return this.getRoutes;
      },
      set(value) {
        this.SET_ROUTES(value);
      },
    },
    dashboardRoutes: {
      get() {
        return this.getDashboardRoutes;
      },
      set(value) {
        this.SET_DASHBOARD_ROUTES(value);
      },
    },
    matrixDashboardRoutes: {
      get() {
        return this.getMatrixDashboardRoutes;
      },
      set(value) {
        this.SET_MATRIX_DASHBOARD_ROUTES(value);
      },
    },
    expandendInternalMenu: {
      get() {
        return this.getIsExpanded;
      },
      set(value) {
        this.SET_IS_EXPANDED(value);
      },
    },
  },
  methods: {
    ...mapMutations('interface', [
      'SET_ROUTES',
      'SET_INTERNAL_MENU_SELECTED',
      'SET_DASHBOARD_ROUTES',
      'SET_MATRIX_DASHBOARD_ROUTES',
      'SET_IS_EXPANDED',
    ]),
    toggleMenu() {
      this.toggleSideBar();
      this.toggleNavBar();
    },
    toggleSideBar() {
      if (this.windowWidth > 768) {
        this.showSideBar = !this.showSideBar;
        this.expandendSideBar = false;
      }
    },
    expandSideBar() {
      if (this.windowWidth > 1024) {
        this.expandendSideBar = this.SET_IS_EXPANDED(!this.expandendSideBar);
      }
    },
    expandInternalMenu() {
      if (this.windowWidth > 1024) {
        this.expandendInternalMenu = !this.expandendInternalMenu;
      }
    },
    getExpandvalue(sidebar) {
      if (this.windowWidth > 1024) {
        if (this.getInternalMenuSelected != null) {
          if (this.getBroilerRoles != null && this.getInternalMenuSelected.label == "aves") {
            sidebar = !sidebar;
          } else if (this.getMatrixRoles != null && this.getInternalMenuSelected.label == "headquarters") {
            sidebar = !sidebar;
          } else sidebar = false;
        }
      } return sidebar
    },
    toggleNavBar() {
      if (this.windowWidth <= 768) {
        this.showNavBar = !this.showNavBar;
      }
    },
    onResize() {
      this.windowWidth = window.screen.width;
    },
    updateRoutesState(route) {
      this.routes = JSON.parse(JSON.stringify(this.getDefaultRoutes));
      this.dashboardRoutes = JSON.parse(JSON.stringify(this.getDefaultDashboardRoutes));
      this.matrixDashboardRoutes = JSON.parse(JSON.stringify(this.getDefaultMatrixDashboardRoutes));
      this.layingBirdsDefaultDashboardRoutes = JSON.parse(JSON.stringify(this.getLayingBirdsDefaultDashboardRoutes));

      switch (route) {
        case 'Menu':
          this.routes[0].selected = true;
          break;

        // BroilerChicken
        case 'Home':
        case 'PerformanceDashboard':
          this.routes[1].selected = true;
          this.routes[1].accordionMenu[0].selected = true;
          this.routes[1].internal[0].menus[0].selected = true;

          if (route === 'PerformanceDashboard') {
            this.dashboardRoutes[0].menus[0].selected = true;
          }
          break;

        case 'SlaughterPrediction':
        case 'SlaughterPredictionDashboard':
          this.routes[1].selected = true;
          this.routes[1].accordionMenu[1].selected = true;
          this.routes[1].internal[0].menus[1].selected = true;

          if (route === 'SlaughterPredictionDashboard') {
            this.dashboardRoutes[0].menus[1].selected = true;
          }
          break;

        case 'Sanity':
        case 'SanityDashboard':
          this.routes[1].selected = true;
          this.routes[1].accordionMenu[2].selected = true;
          this.routes[1].internal[0].menus[2].selected = true;

          if (route === 'SanityDashboard') {
            this.dashboardRoutes[0].menus[2].selected = true;
          }
          break;

        case 'Nutrition':
        case 'NutritionDashboard':
          this.routes[1].selected = true;
          this.routes[1].accordionMenu[3].selected = true;
          this.routes[1].internal[0].menus[3].selected = true;

          if (route === 'NutritionDashboard') {
            this.dashboardRoutes[0].menus[3].selected = true;
          }
          break;

        case 'RecentAlertsView':
        case 'AlertDashboard':
          this.routes[1].selected = true;
          this.routes[1].accordionMenu[4].selected = true;
          this.routes[1].internal[0].menus[4].selected = true;
          break;

        case 'AlertHistoryView':
        case 'AlertHistoryDashboard':
          this.routes[1].selected = true;
          this.routes[1].accordionMenu[5].selected = true;
          this.routes[1].internal[0].menus[5].selected = true;
          break;

        // Matrix
        case 'MatrixPerformance':
        case 'MatrixPerformanceDashboard':
          this.routes[2].selected = true;
          this.routes[2].accordionMenu[0].selected = true;
          this.routes[2].internal[0].menus[0].selected = true;

          if (route === 'MatrixPerformanceDashboard') {
            this.matrixDashboardRoutes[0].menus[0].selected = true;
            if (this.$route.params.sex == 2) {
              this.matrixDashboardRoutes[0].menus[0].accordionMenu[1].selected = true;
            } else if (this.$route.params.sex == 3) {
              this.matrixDashboardRoutes[0].menus[0].accordionMenu[0].selected = true;
            }
          }
          break;

        case 'MatrixSanity':
        case 'MatrixSanityDashboard':
          this.routes[2].selected = true;
          this.routes[2].accordionMenu[1].selected = true;
          this.routes[2].internal[0].menus[1].selected = true;

          if (route === 'MatrixSanityDashboard') {
            this.matrixDashboardRoutes[0].menus[1].selected = true;
          }
          break;

        case 'MatrixNutrition':
        case 'MatrixNutritionDashboard':
          this.routes[2].selected = true;
          this.routes[2].accordionMenu[2].selected = true;
          this.routes[2].internal[0].menus[2].selected = true;

          if (route === 'MatrixNutritionDashboard') {
            this.matrixDashboardRoutes[0].menus[2].selected = true;
          }
          break;

        case 'MatrixRecentAlertsView':
        case 'MatrixAlertDashboard':
          this.routes[2].selected = true;
          this.routes[2].accordionMenu[3].selected = true;
          this.routes[2].internal[0].menus[3].selected = true;
          break;

        case 'MatrixAlertHistoryView':
        case 'MatrixAlertHistoryDashboard':
          this.routes[2].selected = true;
          this.routes[2].accordionMenu[4].selected = true;
          this.routes[2].internal[0].menus[4].selected = true;
          break;

        // LayingBirds
        case 'LayingBirdsSanity':
        case 'LayingBirdsSanityDashboard':
          this.routes[3].selected = true;
          this.routes[3].accordionMenu[0].selected = true;
          this.routes[3].internal[0].menus[0].selected = true;

          if (route === 'LayingBirdsSanityDashboard') {
            this.layingBirdsDefaultDashboardRoutes[0].menus[0].selected = true;
          }
          break;

        case 'LayingBirdsNutrition':
        case 'LayingBirdsNutritionDashboard':
          this.routes[3].selected = true;
          this.routes[3].accordionMenu[1].selected = true;
          this.routes[3].internal[0].menus[1].selected = true;

          if (route === 'LayingBirdsNutritionDashboard') {
            this.layingBirdsDefaultDashboardRoutes[0].menus[1].selected = true;
          }
          break;

        case 'LayingBirdsRecentAlertsView':
        case 'LayingBirdsAlertDashboard':
          this.routes[3].selected = true;
          this.routes[3].accordionMenu[2].selected = true;
          this.routes[3].internal[0].menus[2].selected = true;
          break;

        case 'LayingBirdsAlertHistoryView':
        case 'LayingBirdsAlertHistoryDashboard':
          this.routes[3].selected = true;
          this.routes[3].accordionMenu[3].selected = true;
          this.routes[3].internal[0].menus[3].selected = true;
          break;

      }


      if (this.getIsMatrix) {
        this.dashboardRoutes[0].menus[1].disabled = true;
      }
      if (this.getSelectedShed?.id !== this.getSelectedSilo?.shed?.id) {
        this.dashboardRoutes[0].menus[3].disabled = true;
      }
      this.SET_INTERNAL_MENU_SELECTED();
    }
  },
  watch: {
    windowWidth(newWidth) {
      if (newWidth <= 1360) {
        this.expandendSideBar = false;
      }
      if (newWidth <= 768) {
        this.showSideBar = false;
      } else {
        this.showSideBar = true;
      }
    },
    '$route'(to, from) {
      this.updateRoutesState(to.name);
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
    if (this.windowWidth <= 768) {
      this.showSideBar = false;
    }
    if (this.windowWidth <= 1024) {
      this.expandedSideBar = false;
    }
  },
  created() {
    this.updateRoutesState(this.$route.name);
  },
};
</script>

<style lang="scss">
@use '@/assets/styles/tooltip.scss';
</style>
