<template>
  <div v-if="getComfortValues && getComfortIsLoaded"
    class="grid grid-cols-1 grid-rows-4 gap-2 sm:grid-cols-2 sm:grid-rows-2 lg:grid-cols-4 lg:grid-rows-1">
    <div
      v-for="(comfort, index) in getComfortValues" :key="index">
      <ComfortCard
        :comfort="comfort.code"
        :value=comfort.description
        :total="comfort.count"
        :percentage="comfort.percentage"
        :loading="false"
        @click.native="selectOption('enthalpy', comfort.description, 'isEqual', 'comfort', getComfortLabel[comfort.code])" />
    </div>
  </div>
  <div v-else class="grid grid-cols-1 grid-rows-4 gap-2 sm:grid-cols-2 sm:grid-rows-2 lg:grid-cols-4 lg:grid-rows-1">
    <div
      v-for="(comfort, index) in defaultComfort" :key="index">
      <ComfortCard :comfort="comfort" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import ComfortCard from '@/components/comfort/ComfortCard.vue';

import { isNotNull } from '@/utils';

export default {
  name: 'ComfortContainer',
  components: { ComfortCard },
  data() {
    return {
      defaultComfort: [
        0,
        1,
        2,
        3,
      ],
    };
  },
  computed: {
    ...mapGetters('comfort', [
      'getComfortValues', 'getTotalComfort', 'getComfortLabel', 'getComfortIsLoaded'
    ]),
    ...mapGetters('environmentFilter', [
      'getConfiguredFilters'
    ]),
  },
  methods: {
    ...mapActions('environmentFilter', [ 'removeFilter', 'addFilter' ]),
    findFilter(filter, index=null) {
      let filters = this.getConfiguredFilters || [];
      if (isNotNull(index)) {
        return filters.findIndex((f) => f.index = index);
      }
      return filters.findIndex((f) => {
        return f.field == filter.field && f.value == filter.value && f.operator == filter.operator;
      });
    },
    selectOption(field, value, operator, keyLabel=null, optionLabel=null, index=null) {
      if (value === null || value === '') {
        return;
      }
      let finded = this.findFilter({ field, operator, value }, index);
      if (finded >= 0) {
        this.removeFilter(finded);
      } else {
        this.addFilter(
          { field, operator, value, keyLabel, optionLabel, conjunction: false, filtered: false }
        );
      }
    },
  },
};
</script>
