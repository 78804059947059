import { Silo } from "./Silo";

export class SiloGroup {
  constructor(data = null) {
    if (data !== null) {
      this.id = data.id;
      this.name = data.name;
      this.lastBatch = data.last_batch;
      this.stage = data.stage;
      this.total_available = data.total_available;
      this.silos = data.silos.map(silos => new Silo(silos));
    }
  }

  get silosList() {
    return this.silos ?? [];
  }
}
