export const state = {
  comfortValues: [],
  comfortValue: {
    'lethal': '3',
    'critical': '2',
    'intermediary': '1',
    'comfortable': '0',
    'default': '-1',
  },
  comfortIsLoaded: true,
  comfortLabel: {
    '3': 'lethal',
    '2': 'critical',
    '1': 'intermediary',
    '0': 'comfortable',
    '-1': 'no_data',
  },
  comfortBadgeClass: {
    '3': 'comfort-badge lethal-badge',
    '2': 'comfort-badge critical-badge',
    '1': 'comfort-badge intermediary-badge',
    '0': 'comfort-badge comfortable-badge',
    '-1': 'comfort-badge disabled-badge',
  },
  comfortCardClass: {
    '3': 'lethal-gradient',
    '2': 'critical-gradient',
    '1': 'intermediary-gradient',
    '0': 'comfort-gradient',
  },
  comfortAliasNumberClass: {
    '3': 'alias-number lethal-alias-number',
    '2': 'alias-number critical-alias-number',
    '1': 'alias-number intermediary-alias-number',
    '0': 'alias-number comfortable-alias-number',
    '-1': 'alias-number default-alias-number',
  },
  comfortIcon: {
    '3': 'fas fa-exclamation',
    '2': 'fas fa-exclamation',
    '1': 'fas fa-exclamation',
    '0': 'fas fa-check',
    '-1': 'fas fa-times',
  },
  comfortCardIcon: {
    '3': 'fas fa-exclamation-triangle',
    '2': 'fas fa-exclamation-triangle',
    '1': 'fas fa-exclamation-triangle',
    '0': 'fas fa-check-circle',
  }
};
