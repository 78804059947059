export const getters = {
  getTotalSheds(state) {
    return state.totalSheds;
  },

  getPcpPlanningSheds(state) {
    return state.pcpPlanningSheds;
  },

  getReportSelectedList(state) {
    return state.reportSelectedList;
  },
  getSlaughterWeightProjection(state) {
    return state.slaughterWeightProjection;
  },
  getTableOptions(state) {
    return state.tableOptions;
  },
};
