export const isAuthenticated = function (to, from, next, router) {
  if (router.app.$session.exists()) {
    next();
  } else {
    next({ name: 'Login' });
  }
  return false;
};

export const hasBroilerRoles = function (to, from, next, router, store, role) {
  if (router.app.$session.exists()) {
    store.state.environmentFilter.isMatrix = false;
    store.state.environmentFilter.isLayingBirds = false;
    if (store.state.user.roles["frangos_de_corte"] != null) {
      if ((store.state.user.roles["frangos_de_corte"].includes(role))) {
        return next()
      } else {
        return next(false)
      }
    } return next(false)
  } return next({ name: 'Login' });
}

export const hasMatrixRoles = function (to, from, next, router, store, role) {
  if (router.app.$session.exists()) {
    store.state.environmentFilter.isMatrix = true;
    store.state.environmentFilter.isLayingBirds = false;
    if (store.state.user.roles["matrizeiro"] != null) {
      if ((store.state.user.roles["matrizeiro"].includes(role))) {
        return next()
      } else {
        return next(false)
      }
    } return next(false)
  } return next({ name: 'Login' });
}

export const hasLayingBirdsRoles = function (to, from, next, router, store, role) {
  if (router.app.$session.exists()) {
    store.state.environmentFilter.isMatrix = false;
    store.state.environmentFilter.isLayingBirds = true;
    if (store.state.user.roles["aves_poedeiras"] != null) {
      if ((store.state.user.roles["aves_poedeiras"].includes(role))) {
        return next()
      } else {
        return next(false)
      }
    } return next(false)
  } return next({ name: 'Login' });
}
