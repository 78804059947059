<template>
  <div>
    <v-app>
      <v-data-table :headers="header" :items="sheds" :server-items-length="totalSheds" :options.sync="options"
        :loading="loading" item-key="key" sort-by="name" class="custom-table"
        :footer-props="{ 'items-per-page-text': $t('aviaries_page') }" @click:row="clickTableItem">
        <template slot="progress">
          <div></div>
        </template>

        <template slot="loading">
          <div class="flex flex-row justify-center">
            <SpinnerLoading class="z-50 text-gray-400" />
            <span>{{ $t("loading_properties") }}...</span>
          </div>
        </template>

        <template slot="no-data">
          <div class="flex flex-row justify-center">
            <span>{{ $t("none_found") }}.</span>
          </div>
        </template>

        <template v-slot:[`item.core`]="{ item }">
          <span :class="{ 'hidden-sensitive-text': getIsGuest }">
            {{ item.core }}
          </span>
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <span :class="{ 'hidden-sensitive-text': getIsGuest }">
            {{ item.name }}
          </span>
        </template>

        <template v-slot:[`item.enthalpy`]="{ item }">
          <div class="flex items-center justify-center">
            <ComfortBadge :comfort=item.enthalpy.code :time=item.enthalpy.timestamp />
          </div>
        </template>

        <template v-slot:[`item.performance`]="{ item }">
          <div :class="{
            'negative-performance': item.performance < 100,
            'positive-performance': item.performance >= 100,
          }">
            {{ formatPercentageNumber(typeof item.performance === "number" ? parseFloat(item.performance.toFixed(1)) :
            '--') }}
          </div>
        </template>

        <template v-slot:[`item.uniformity`]="{ item }">
          <div>
            {{ formatPercentageNumber(typeof item.uniformity === "number" ? parseFloat(item.uniformity.toFixed(1)) :
            '--') }}
          </div>
        </template>

        <template v-slot:[`item.conversionFactor`]="{ item }">
          <div>
            {{ formatNumber(typeof item.conversionFactor === "number" ? parseFloat(item.conversionFactor.toFixed(2)) :
            '--') }}
          </div>
        </template>

        <template v-slot:[`item.aerosacculitis`]="{ item }">
          <div>
            {{ formatPercentageNumber(item.aerosacculitis) }}
          </div>
        </template>
      </v-data-table>
    </v-app>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

import ComfortBadge from '@/components/comfort/ComfortBadge.vue';
import SpinnerLoading from '@/components/ui/SpinnerLoading.vue';

import i18n from '@/plugins/i18n';

import {
  isEqual,
  isBigger,
  includesText,
  isExactly,
  isSmaller,
  isNotNull,
} from '@/utils';

import { format } from 'date-fns';

export default {
  name: 'PropertyDataTable',
  components: { ComfortBadge, SpinnerLoading },
  props: {
    state: {
      type: String,
      default: 'performance',
      validator(value) {
        return ['performance', 'slaughter-prediction', 'sanity', 'nutrition'].includes(value);
      },
    },
  },
  data() {
    return {
      operators: { isEqual, isBigger, includesText, isExactly, isSmaller },
      sheds: [],
      totalSheds: 0,
      options: {},
      loading: true,
      currentPage: 1,
    };
  },
  watch: {
    options() {
      this.getShedsListElements();
    },
    deep: true,
    getShowDisabledProperties() {
      this.options.page = 1;
      if (this.options.itemsPerPage === -1) {
        this.options.itemsPerPage = 10;
      }
      this.getShedsListElements();
    },
    getShowSlaughterState() {
      this.options.page = 1;
      if (this.options.itemsPerPage === -1) {
        this.options.itemsPerPage = 10;
      }
      this.getShedsListElements();
    },
    getShowPreLodgeState() {
      this.options.page = 1;
      if (this.options.itemsPerPage === -1) {
        this.options.itemsPerPage = 10;
      }
      this.getShedsListElements();
    },
    getShowLodgeState() {
      this.options.page = 1;
      if (this.options.itemsPerPage === -1) {
        this.options.itemsPerPage = 10;
      }
      this.getShedsListElements();
    },
    getShowPriorityState() {
      this.options.page = 1;
      if (this.options.itemsPerPage === -1) {
        this.options.itemsPerPage = 10;
      }
      this.getShedsListElements();
    },
    getConfiguredFilters() {
      this.options.page = 1;
      if (this.options.itemsPerPage === -1) {
        this.options.itemsPerPage = 10;
      }
      this.getShedsListElements();
    },
    getSearchSlaughterPrediction() {
      this.options.page = 1;
      if (this.options.itemsPerPage === -1) {
        this.options.itemsPerPage = 10;
      }
      this.getShedsListElements();
    },
  },
  computed: {
    ...mapGetters('environmentFilter', [
      'getConfiguredFilters',
      'getShowDisabledProperties',
      'getShowSlaughterState',
      'getShowLodgeState',
      'getShowPreLodgeState',
      'getShowPriorityState',
      'getSearchSlaughterPrediction',
      'getFlagSlaughterPrediction',
      'getSearchSlaughterPrediction',
      'getIsMatrix',
      'getIsLayingBirds'
    ]),
    ...mapGetters('user', ['getIsGuest']),
    header() {
      if (this.state == 'slaughter-prediction') {
        if (this.searchSlaughterPrediction != null && this.getFlagSlaughterPrediction) {
          return [
            { text: this.$t('core'), value: 'core', align: 'center', sortable: true },
            { text: this.$t('shed'), value: 'name', align: 'center', sortable: true },
            { text: this.$t('age'), value: 'ageForecast', align: 'center', sortable: true },
            { text: this.$t('date'), value: 'dateForecast', align: 'center', sortable: true },
            { text: this.$t('fwu') + ' (g)', value: 'updatedProjectedWeight', align: 'center', sortable: true },
          ];
        }
        return [
          { text: this.$t('core'), value: 'core', align: 'center', sortable: true },
          { text: this.$t('shed'), value: 'name', align: 'center', sortable: true },
          { text: this.$t('age'), value: 'age', align: 'center', sortable: true },
          { text: this.$t('weight') + ' (g)', value: 'weight', align: 'center', sortable: true },
          { text: this.$t('performance'), value: 'performance', align: 'center', sortable: true },
          { text: this.$t('uniformity'), value: 'uniformity', align: 'center', sortable: true },
          { text: this.$t('last_projected_weight') + ' (g)', value: 'weightProjection', align: 'center', sortable: true },
        ];
      } else if (this.state == 'sanity') {
        if (this.getIsMatrix) {
          return [
            { text: this.$t('comfort'), value: 'enthalpy', align: 'center', sortable: true },
            { text: this.$t('core'), value: 'core', align: 'center', sortable: true },
            { text: this.$t('shed'), value: 'name', align: 'center', sortable: true },
            { text: this.$t('production_type'), value: 'productionType', align: 'center', sortable: false },
            { text: this.$t('lineage'), value: 'lineage', align: 'center', sortable: true },
            { text: this.$t('sex'), value: 'sex', align: 'center', sortable: true },
            { text: this.$t('batch_age'), value: 'age', align: 'center', sortable: true },
            { text: this.$t('real_age'), value: 'real_age', align: 'center', sortable: true },
            { text: this.$t('aerosacculitis'), value: 'aerosacculitis', align: 'center', sortable: true },
            { text: this.$t('contaminated'), value: 'contaminated', align: 'center', sortable: true },
          ];
        }
        return [
          { text: this.$t('comfort'), value: 'enthalpy', align: 'center', sortable: true },
          { text: this.$t('core'), value: 'core', align: 'center', sortable: true },
          { text: this.$t('shed'), value: 'name', align: 'center', sortable: true },
          { text: this.$t('lineage'), value: 'lineage', align: 'center', sortable: true },
          { text: this.$t('sex'), value: 'sex', align: 'center', sortable: true },
          { text: this.$t('age'), value: 'age', align: 'center', sortable: true },
          { text: this.$t('aerosacculitis'), value: 'aerosacculitis', align: 'center', sortable: true },
          { text: this.$t('contaminated'), value: 'contaminated', align: 'center', sortable: true },
        ];
      } else {
        if (this.getIsMatrix) {
          return [
            { text: this.$t('comfort'), value: 'enthalpy', align: 'center', sortable: true },
            { text: this.$t('core'), value: 'core', align: 'center', sortable: true },
            { text: this.$t('shed'), value: 'name', align: 'center', sortable: true },
            { text: this.$t('production_type'), value: 'productionType', align: 'center', sortable: true },
            { text: this.$t('lineage'), value: 'lineage', align: 'center', sortable: true },
            { text: this.$t('sex'), value: 'sex', align: 'center', sortable: true },
            { text: this.$t('batch_age'), value: 'age', align: 'center', sortable: true },
            { text: this.$t('real_age'), value: 'real_age', align: 'center', sortable: true },
          ];
        }
        return [
          { text: this.$t('comfort'), value: 'enthalpy', align: 'center', sortable: true },
          { text: this.$t('core'), value: 'core', align: 'center', sortable: true },
          { text: this.$t('shed'), value: 'name', align: 'center', sortable: true },
          { text: this.$t('lineage'), value: 'lineage', align: 'center', sortable: true },
          { text: this.$t('sex'), value: 'sex', align: 'center', sortable: true },
          { text: this.$t('age'), value: 'age', align: 'center', sortable: true },
          { text: this.$t('weight') + ' (g)', value: 'weight', align: 'center', sortable: true },
          { text: this.$t('dwg') + ' (g)', value: 'gain', align: 'center', sortable: true },
          { text: this.$t('uniformity'), value: 'uniformity', align: 'center', sortable: true },
          { text: this.$t('conversionFactor'), value: 'conversionFactor', align: 'center', sortable: true },
          { text: this.$t('performance'), value: 'performance', align: 'center', sortable: true },
        ];
      }
    },
    searchSlaughterPrediction: {
      get() {
        return this.getSearchSlaughterPrediction;
      },
      set(value) {
        this.SET_SEARCH_SLAUGHTER_PREDICTION(value);
      },
    },
  },
  methods: {
    ...mapGetters('environment', ['getSheds', 'getTotalSheds']),
    ...mapGetters('environmentFilter', ['getRequestParams']),
    ...mapActions('environment', ['loadSheds']),
    ...mapActions('environmentFilter', ['resolveParams']),
    ...mapActions('comfort', ['setTotalComfortValues']),
    ...mapMutations('environment', [
      'SET_MODAL_SLAUGHTER_PREDICTION', 'SET_ENVIRONMENT_SLAUGHTER_PREDICTION', 'SET_SHEDS_IS_LOADED'
    ]),
    ...mapMutations('environmentFilter', [
      'SET_SEARCH_SLAUGHTER_PREDICTION',
      'SET_IS_HATCH'
    ]),
    async getShedsListElements() {
      let { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.SET_SHEDS_IS_LOADED(false);
      this.loading = true;
      this.sheds = [];
      await this.resolveParams();
      let params = this.getRequestParams();
      itemsPerPage = itemsPerPage === -1 ? this.totalSheds : itemsPerPage;

      if (sortBy.length === 1 && sortDesc.length === 1) {
        let sortingParam = this.getSortingParameter(sortBy[0]);
        if (sortDesc[0]) {
          sortingParam = '-' + sortingParam;
        }
        params = { ...params, ordering: sortingParam }
      }

      this.loadSheds({
        params: params,
        state: this.state == 'nutrition' ? null : this.state,
        page: page,
        itemsPerPage: itemsPerPage
      }).then(() => {

        this.setTotalComfortValues();
        this.sheds = this.getSheds();
        this.totalSheds = this.getTotalSheds();

        if (this.state == 'slaughter-prediction' && this.searchSlaughterPrediction != null) {
          this.sheds = this.sheds.map((shed, index) => {
            let weightProjectionData = this.getWeightProjectionData(
              this.getSearchSlaughterPrediction.field,
              this.getSearchSlaughterPrediction.value,
              this.getSearchSlaughterPrediction.operator,
              shed.updatedWeightProjectionList
            );

            Object.assign(shed, weightProjectionData);

            return shed
          });

          if (this.sheds && sortBy.length === 1 && sortDesc.length === 1) {
            this.sheds = this.sheds.sort((a, b) => {
              const sortA = a[sortBy[0]]
              const sortB = b[sortBy[0]]

              if (sortDesc[0]) {
                if (sortA < sortB) return 1;
                if (sortA > sortB) return -1;
                return 0;
              } else {
                if (sortA < sortB) return -1;
                if (sortA > sortB) return 1;
                return 0;
              }
            })
          }
        }

        if (this.state === 'slaughter-prediction') {
          this.sheds = this.sheds.filter(shed => shed.weightProjection !== '--');
        }

        this.loading = false;
      }).catch((err) => {
        this.$session.destroy();
        if (this.$router.path !== '/login') {
          this.$router.push('/login');
        }
        throw err;
      });
    },
    getDateFormatted(date) {
      try {
        return format(new Date(date), 'dd/MM/yyyy HH:mm');
      } catch (err) {
        return '--';
      }
    },
    clickTableItem(item) {
      if (this.state == 'slaughter-prediction') {
        this.$router.push({ name: 'SlaughterPredictionDashboard', params: { id: item.id } });
      } else if (this.state == 'sanity') {
        if (this.getIsMatrix) {
          if (item.getBox().length == 8) {
            this.SET_IS_HATCH(true)
            this.$router.push({ name: 'MatrixSanityDashboard', params: { id: item.id } });
          } else {
            this.SET_IS_HATCH(false)
            this.$router.push({ name: 'MatrixSanityDashboard', params: { id: item.id } });
          }
        } else if (this.getIsLayingBirds) {
          this.$router.push({ name: 'LayingBirdsSanityDashboard', params: { id: item.id } });
        } else {
          this.$router.push({ name: 'SanityDashboard', params: { id: item.id } });
        }
      } else {
        if (this.getIsMatrix) {
          let batch_sex = item.lastBatch.sex.external_id
          if (item.getBox().length == 8) {
            this.SET_IS_HATCH(true)
            if (batch_sex == 3 || batch_sex == 2) {
              this.$router.push({ name: 'MatrixPerformanceDashboard', params: { id: item.id, sex: batch_sex } });
            } else {
              this.$router.push({ name: 'MatrixPerformanceDashboard', params: { id: item.id, sex: 2 } });
            }
          } else {
            this.SET_IS_HATCH(false)
            if (batch_sex == 3 || batch_sex == 2) {
              this.$router.push({ name: 'MatrixPerformanceDashboard', params: { id: item.id, sex: batch_sex } });
            } else {
              this.$router.push({ name: 'MatrixPerformanceDashboard', params: { id: item.id, sex: 2 } });
            }
          }
        } else if (this.getIsLayingBirds) {
          this.$router.push({ name: 'LayingBirdsPerformanceDashboard', params: { id: item.id } })
        } else {
          this.$router.push({ name: 'PerformanceDashboard', params: { id: item.id } });
        }
      }
    },
    formatPercentageNumber(number) {
      if (typeof number != 'number') {
        return number;
      }
      if (number % 1 === 0) {
        return number.toLocaleString(i18n.locale) + ',0%';
      }
      return number.toLocaleString(i18n.locale) + '%';
    },
    formatNumber(number) {
      if (typeof number != 'number') {
        return number;
      }
      if (number % 1 === 0) {
        return number.toLocaleString(i18n.locale) + ',00';
      }
      return number.toLocaleString(i18n.locale);
    },
    getWeightProjectionData(field, value, operator, weightProjectionList) {
      try {
        if (isNotNull(weightProjectionList)) {
          let list = [...weightProjectionList];
          if (field === 'predict_weight') {
            if (operator === 'isBigger') {
              list.reverse();
            }
          }
          let found = list.find(e => {
            return this.operators[operator](e[field], value)
          });
          if (isNotNull(found)) {
            if (Array.isArray(found)) {
              found = found[0];
            }
            return { ageForecast: found.predict_age, dateForecast: this.getDateFormatted(found.adjusted_date), updatedProjectedWeight: Math.round(found.predict_weight) };
          } else {
            return { ageForecast: '--', dateForecast: '--', updatedProjectedWeight: '--' };
          }
        }
        return { ageForecast: '--', dateForecast: '--', updatedProjectedWeight: '--' };
      } catch (err) {
        return { ageForecast: '--', dateForecast: '--', updatedProjectedWeight: '--' };
      }
    },
    getSortingParameter(sortBy) {
      sortBy = sortBy.split('.')[1] || sortBy;
      switch (sortBy) {
        case 'weightProjection':
          return 'predicted_weight';
        case 'uniformity':
          return 'variation_coef';
        case 'productionType':
          return 'production_type';
        case 'name':
          return 'producer';
        case 'conversionFactor':
          return 'conversion_factor';
        default:
          return sortBy;
      }
    }
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .negative-performance {
    @apply font-bold text-red-400
  }

  .positive-performance {
    @apply font-bold text-green-400
  }

  .v-application--wrap {
    min-height: 0 !important;
    background: none !important;
    color: none !important;
    line-height: none !important;
  }

  .v-application li {
    list-style-type: none;
  }

  .v-data-table {
    border-radius: 0.25rem !important;
    background-color: #e5e7eb !important;
    border-width: 1px !important;
    border-color: #e5e7eb !important;
    box-shadow: none !important;
    z-index: 0;
  }

  .v-data-table__wrapper {
    padding-bottom: 15px;
  }

  .v-data-footer {
    margin-top: -15px;
  }

  .v-data-table thead {
    font-size: 0.875rem !important;
    background-color: #e5e7eb !important;
  }

  .v-data-table .v-data-table-header span {
    font-size: 12px !important;
  }

  .v-data-table tbody {
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    color: #6b7280 !important;
    border-color: #e5e7eb !important;
  }

  .v-data-footer {
    border: none !important;
  }

  .v-data-table tr {
    border-color: rgb(229 231 235) !important;
  }

  .v-data-table tbody tr {
    cursor: pointer;

    &:nth-child(odd) {
      background-color: white;
    }

    &:nth-child(even) {
      background-color: #f9fafb;
    }

    &:hover {
      background-color: #f3f4f6 !important;
    }
  }

  .v-data-table th,
  .v-data-table td {
    height: 2.25rem !important;
    padding: 0 5px !important;
  }

  .v-data-table__mobile-row {
    padding: 0px 10px !important;
  }

  .v-data-table-header th {
    white-space: nowrap !important;
  }

  .v-data-table--mobile .v-data-table__empty-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .v-data-table--mobile .v-data-table__empty-wrapper td {
    display: flex;
    align-items: center;
  }

  .v-data-table-header-mobile__wrapper .v-input {
    background-color: white !important;
    padding: 10px !important;
    padding-top: 15px !important;
    border-radius: 10px !important;
  }

  .v-data-table th {
    border-radius: 0.25rem !important;
    transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1) !important;
    cursor: pointer;
    color: #374151 !important;
    font-weight: 700 !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    border: none !important;

    &:hover {
      background-color: #d1d5db;
    }
  }

  .v-data-table td {
    text-align: center !important;
    border-color: #e5e7eb !important;
  }

  .v-application--is-ltr .v-data-footer__select .v-select {
    margin: 7px 0 7px 34px !important;
  }
}
</style>
