export const getters = {
  getModuleOpened(state) {
    return state.moduleOpened;
  },

  getDefaultRoutes(state) {
    return state.defaultRoutes;
  },

  getInternalMenuSelected(state) {
    return state.internalMenuSelected;
  },

  getRoutes(state) {
    return state.routes;
  },

  getIsExpanded(state) {
    return state.isExpanded;
  },

  getDefaultDashboardRoutes(state) {
    return state.defaultDashboardRoutes;
  },

  getLayingBirdsDefaultDashboardRoutes(state) {
    return state.layingBirdsDefaultDashboardRoutes;
  },

  getDashboardRoutes(state) {
    return state.dashboardRoutes;
  },

  getDefaultMatrixDashboardRoutes(state) {
    return state.matrixDefaultDashboardRoutes;
  },

  getMatrixDashboardRoutes(state) {
    return state.matrixDashboardRoutes;
  },
};
