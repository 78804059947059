<template>
  <div>
    <PropertyFilterMenu />
    <div class="bg-white rounded drop-shadow p-2">
      <PropertyDataTable />
    </div>
  </div>
</template>

<script>
import PropertyFilterMenu from '@/components/property/PropertyFilterMenu.vue';
import PropertyDataTable from '@/components/property/PropertyDataTable.vue';

export default {
  name: 'PropertyContainer',
  components: { PropertyFilterMenu, PropertyDataTable },
};
</script>
