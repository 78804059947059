export const mutations = {
  SET_TOKEN(state, token) {
    state.token = token;
  },

  SET_IS_GUEST(state, isGuest) {
    state.isGuest = isGuest;
  },

  SET_NAME(state, name) {
    state.name = name;
  },

  SET_ROLES(state, roles) {
    state.roles = roles
  },

  SET_USER_DATA(state, userData) {
    state.name = userData.person?.first_name ?? userData.username;
    if ((userData.roles['frangos_de_corte']?.includes('Vendas')) || (userData.roles['matrizeiro']?.includes('Vendas'))) {
      if (!userData.is_superuser && !userData.is_staff) {
        state.isGuest = true
      }
    }
  },
};
