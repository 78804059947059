<template>
  <TooltipText :label="$t(tooltipLabel)" v-if="!expanded && tooltip">
    <li
      class="cursor-pointer select-none"
      :class="{ 'bg-gray-100 border-r-4 border-primary-700': active && !small, 'my-1': !small, 'pl-1': !expanded && active }">
      <div
        class="px-4 transform transition-transform ease-in duration-200 hover:text-primary-700"
        :class="[active ? 'text-gray-500' : 'text-gray-400', { 'hover:translate-x-1': expanded }]">
        <div class="py-3 flex items-center" :class="{ 'justify-center': !expanded }">
          <font-awesome-icon :icon="icon" :class="{ 'text-primary-700': active }" 
            v-if="expanded && icon != null" />
          <font-awesome-icon
            :icon="icon"
            size="lg"
            :class="{ 'text-primary-700': active }"
            v-else-if="icon != null" />
          <slot name="icon"></slot>
          <span class="ml-2 font-bold text-xs" v-if="expanded">{{ $t(text) }}</span>
        </div>
      </div>
    </li>
  </TooltipText>
  <li
    class="cursor-pointer select-none"
    v-else
    :class="{ 'bg-gray-100 border-r-4 border-primary-700': active && !small, 'my-1': !small, 'pl-1': !expanded && active, 'small-icon': expanded }">
    <div
      class="transform transition-transform ease-in duration-200 hover:text-primary-700"
      :class="[active ? 'text-gray-500' : 'text-gray-400', { 'hover:translate-x-1': expanded && !small }, { 'px-4': !small }]">
      <div class="flex items-center" :class="[{ 'justify-center': !expanded }, { 'py-3': !small }]">
        <font-awesome-icon :icon="icon" :class="{ 'text-primary-700': active }" v-if="expanded && icon != null" />
        <font-awesome-icon :icon="icon" size="lg" :class="{ 'text-primary-700': active }" v-else-if="icon != null" />
        <slot name="icon"></slot>
        <span class="ml-2 font-bold text-xs" v-if="expanded" style="white-space: nowrap;">{{ $t(text) }}</span>
      </div>
    </div>
  </li>
</template>

<script>
import TooltipText from '@/components/ui/TooltipText.vue';

export default {
  name: 'NavOption',
  components: { TooltipText },
  props: {
    icon: { type: String, default: null },
    text: { type: String, required: true },
    active: { type: Boolean, default: false },
    expanded: { type: Boolean, default: true },
    small: { type: Boolean, default: false },
    tooltip: { type: Boolean, default: false },
    tooltipLabel: { type: String },
  },
};
</script>
