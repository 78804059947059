<template>
  <div>
    <div class="text-primary-700 flex items-center justify-between h-11">
      <div class="flex items-center">
        <font-awesome-icon icon="fa-solid fa-chart-line" size="lg"></font-awesome-icon>
        <h2 class="text-xl lg:text-2xl font-bold ml-2">Performance</h2>
      </div>
    </div>
    <div>
      <div id="comfort" class="mb-2">
        <div class="text-gray-500 flex items-center">
          <font-awesome-icon icon="fa-solid fa-book-medical" />
          <h3 class="text-base lg:text-lg font-bold ml-2">{{ $t('comfort') }}</h3>
        </div>
        <ComfortList class="mt-2" />
      </div>
      <div id="aviaries">
        <div class="flex flex-col sm:flex-row justify-between">
          <div class="text-gray-500 flex items-center">
            <font-awesome-icon icon="fa-solid fa-list" />
            <h3 class="text-base lg:text-lg font-bold ml-2">{{ $t('aviaries') }}</h3>
          </div>
          <div class="flex flex-row flex-wrap">
            <div
              @click="disabledProperties = !disabledProperties"
              class="mr-1 mt-1"
              :class="[disabledProperties ? 'toggle-button-selected' : 'filter-button']">
              {{ $t('empty_batch') }}
            </div>
            <div
              @click="preLodgeState = !preLodgeState"
              class="mr-1 mt-1"
              :class="[preLodgeState ? 'toggle-button-selected' : 'filter-button']">
              {{ $t('pre_lodged') }}
            </div>
            <div
              @click="lodgeState = !lodgeState"
              class="mr-1 mt-1"
              :class="[lodgeState ? 'toggle-button-selected' : 'filter-button']">
              {{ $t('lodged') }}
            </div>
            <div
              @click="slaughterState = !slaughterState"
              class="mr-1 mt-1"
              :class="[slaughterState ? 'toggle-button-selected' : 'filter-button']">
              {{ $t('slaughter') }}
            </div>
            <div
              @click="priorityState = !priorityState"
              class="mr-1 mt-1"
              :class="[priorityState ? 'toggle-button-selected' : 'filter-button']">
              {{ $t('priority') }}
            </div>
          </div>
        </div>
        <PropertyContainer />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import ComfortList from '@/components/comfort/ComfortList.vue';
import PropertyContainer from '@/components/property/PropertyContainer.vue';

export default {
  name: 'HomeView',
  components: {
    ComfortList,
    PropertyContainer,
  },
  methods: {
    ...mapActions('user', {
      loadUserData: 'loadUserData',
    }),
    ...mapActions('environment', [
      'loadSheds',
      'parseProperties',
      'resetShedsIsLoaded',
      'resetPropertiesValues',
    ]),
    ...mapActions('environmentFilter', ['clearFilteringValue', 'resolveParams']),
    ...mapActions('comfort', ['setTotalComfortValues', 'resetEnthalpyValues']),
    ...mapMutations('environmentFilter', [
      'SET_SHOW_DISABLED_PROPERTIES',
      'SET_SHOW_SLAUGHTER_STATE',
      'SET_SHOW_LODGE_STATE',
      'SET_SHOW_PRE_LODGE_STATE',
      'SET_SHOW_PRIORITY_STATE',
      'SET_CONFIGURED_FILTERS',
    ]),
  },
  computed: {
    ...mapGetters('environment', ['getSheds', 'getShedsIsLoaded', 'getSingleShed']),
    ...mapGetters('environmentFilter', [
      'getShowDisabledProperties',
      'getShowSlaughterState',
      'getShowLodgeState',
      'getShowPreLodgeState',
      'getShowPriorityState',
      'getIsMatrix'
      ]),
    disabledProperties: {
      get() {
        return this.getShowDisabledProperties;
      },
      set(value) {
        if (this.getShedsIsLoaded) this.SET_SHOW_DISABLED_PROPERTIES(value);
      },
    },
    slaughterState: {
      get() {
        return this.getShowSlaughterState;
      },
      set(value) {
        if (this.getShedsIsLoaded) this.SET_SHOW_SLAUGHTER_STATE(value);
      },
    },
    lodgeState: {
      get() {
        return this.getShowLodgeState;
      },
      set(value) {
        if (this.getShedsIsLoaded) this.SET_SHOW_LODGE_STATE(value);
      },
    },
    preLodgeState: {
      get() {
        return this.getShowPreLodgeState;
      },
      set(value) {
        if (this.getShedsIsLoaded) this.SET_SHOW_PRE_LODGE_STATE(value);
      },
    },
    priorityState: {
      get() {
        return this.getShowPriorityState;
      },
      set(value) {
        if (this.getShedsIsLoaded) this.SET_SHOW_PRIORITY_STATE(value);
      },
    },
  },
  created() {
    document.title = 'Trinovati - Aviários';
    this.resetPropertiesValues();
    this.resetEnthalpyValues();
    this.SET_SHOW_DISABLED_PROPERTIES(false);
    this.SET_SHOW_SLAUGHTER_STATE(false);
    this.SET_SHOW_LODGE_STATE(true);
    this.SET_SHOW_PRE_LODGE_STATE(false);
    this.SET_SHOW_PRIORITY_STATE(false);
    this.SET_CONFIGURED_FILTERS([]);
  },
};
</script>
