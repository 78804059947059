<template>
  <li class="side-bar-menu-item external">
    <div class="container-menu-item"
      :class="{ 'disabled': disabled, 'expanded': moduleOpened == label, 'selected': selected }">
      <!-- Icon -->
      <FTooltip :theme="tooltipContent.theme" :placement="tooltipContent.placement">
        <div class="container-icon main" @click="toggleAccordion">
          <font-awesome-icon v-if="icon" class="side-bar-icon" :icon="icon" />
          <RoosterIcon v-if="roosterIcon" class="w-5"></RoosterIcon>
          <LayingBirdsIcon v-if="layingBirdsIcon" class="w-5" />
        </div>

        <template #popper>
          <span>{{ $t(tooltipContent.content) }}</span>
        </template>
      </FTooltip>

      <!-- Accordion -->
      <div v-if="moduleOpened == label && accordion == true" class="container-accordion">
        <div v-for="(menu, i) in accordionMenus" :key="i">
          <router-link v-if="menu.divider == null" :to="menu.route || ''" :disabled="!getHasPermission(menu, label)"
            :event="getHasPermission(menu, label) ? 'click' : ''">
            <FTooltip :theme="menu.tooltip.theme" :placement="menu.tooltip.placement">
              <div class="container-icon" :class="[menu.selected ? 'selected' : '', { 'disabled': menu.disabled }]">
                <font-awesome-icon :class="getClass(menu, label)" :icon="getIcon(menu, label)" />
              </div>
              <template #popper>
                <span>{{ $t(menu.tooltip.content) }}</span>
              </template>
            </FTooltip>
          </router-link>
          <div v-else class="divider"></div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import RoosterIcon from '@/assets/icons/RoosterIcon.vue';
import LayingBirdsIcon from '@/assets/icons/LayingBirdsIcon.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'ExternalMenuItem',
  components: {
    RoosterIcon,
    LayingBirdsIcon,
  },
  props: {
    showTooltip: { type: Boolean, default: false },
    tooltipContent: { type: Object },
    disabled: { type: Boolean, default: false },
    accordion: { type: Boolean, default: false },
    selected: { type: Boolean, default: false },
    icon: { type: String, default: null },
    roosterIcon: { type: Boolean, default: false },
    layingBirdsIcon: { type: Boolean, default: false },
    accordionMenus: { type: Array },
    label: { type: String, required: true },
    hasInternalContent: { type: Boolean, default: false },
    isMatrix: { type: Boolean, default: false },
    role: { type: String, default: null }
  },
  data() {
    return {
      expandedAccordion: false,
    };
  },
  computed: {
    ...mapGetters('interface', ['getModuleOpened']),
    ...mapGetters('user', ['getBroilerRoles', 'getMatrixRoles', 'getLayingBirdsRoles']),
    moduleOpened: {
      get() {
        return this.getModuleOpened;
      },
      set(value) {
        this.SET_MODULE_OPENED(value);
      },
    },
  },
  methods: {
    ...mapMutations('interface', ['SET_MODULE_OPENED']),
    toggleAccordion() {
      if (this.accordion) {
        this.expandedAccordion = !this.expandedAccordion;
      }

      if (this.hasInternalContent) {
        if (this.moduleOpened == null) {
          this.moduleOpened = this.label;
        } else if (this.moduleOpened == this.label) {
          this.moduleOpened = null;
        } else {
          this.moduleOpened = this.label;
        }
      }
    },
    getIcon(icon, label) {
      const rolesMap = {
        'aves': this.getBroilerRoles,
        'headquarters': this.getMatrixRoles,
        'laying_birds': this.getLayingBirdsRoles
      };
      const roles = rolesMap[label];
      return roles && roles.includes(icon.role) ? icon.icon : icon.disabledIcon;
    },
    getHasPermission(icon, label) {
      const rolesMap = {
        'aves': this.getBroilerRoles,
        'headquarters': this.getMatrixRoles,
        'laying_birds': this.getLayingBirdsRoles
      };
      const roles = rolesMap[label];
      return roles ? roles.includes(icon.role) : false;
    },
    getClass(icon, label) {
      const rolesMap = {
        'aves': this.getBroilerRoles,
        'headquarters': this.getMatrixRoles,
        'laying_birds': this.getLayingBirdsRoles
      };
      const roles = rolesMap[label];
      const defaultClass = "my-2 transition cursor-not-allowed text-gray-500";
      return roles && roles.includes(icon.role) ? "" : defaultClass;
    },
  },
};
</script>
