export function getPcpTargetValue(lastBatch, company) {
  if (lastBatch && company) {
    const lastBatchPcpTarget = lastBatch.pcp_target;
    const lastBatchProductionType = lastBatch.production_type.id;

    if (lastBatchPcpTarget) {
      if (lastBatchPcpTarget.motivation == 'slaughter') {
        return lastBatchPcpTarget.target_value;
      }
    }

    const defaultPcpTargets = company.pcp_targets;
    if (defaultPcpTargets) {
      const productionTypePcp = defaultPcpTargets.find(pcp => {
        return pcp.production_type == lastBatchProductionType && pcp.motivation == 'slaughter';
      });

      if (productionTypePcp) {
        return productionTypePcp.target_value;
      }
    }

    const pcpProjections = lastBatch.pcp_projections;
    const age = pcpProjections.find(pcp => {
      return pcp.age == 45;
    });

    if (age) {
      return age.projection;
    }
  }

  return null;
}
