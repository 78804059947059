import format from 'date-fns/format';
import { isNotNull } from '@/utils';
import { getPcpTargetValue } from '@/utils/pcp';

export class Shed {
  constructor(data = null) {
    if (data !== null) {
      this.id = data.id;
      this.name = data.name;
      this.target_value = data.target_value;
      this.target_weight = data.target_weight;
      this.target_date = data.target_date;
      this.target_age = data.target_age;
      this.target_achieved = data.target_achieved;
      this.description = data.description;
      this.total_invoice_deliveries = data.total_invoice_deliveries;
      this.total_available = data.total_available;
      this.ration_forecast = data.ration_forecast;
      this.identification = data.identification;
      this.stage = data.stage;
      this.property = data.property;
      this.localization = data.localization;
      this.company = data.company;
      this.shedType = data.shed_type;
      this.lastBatch = data.last_batch;
      this.lastMetrics = data.last_metrics;
      this.conversionFactor = data.conversion_factor;
      this.silos = data.silos || [];
      this.devicesList = [];
      this.batches = [];
    }
  }

  get propertyName() {
    return this.property?.name ?? '--';
  }

  get core() {
    if (this.property?.property_type === "Núcleo") {
      return this.property?.name ?? '--';
    } else {
      return '--';
    }
  }

  get conversionValue() {
    return this.conversionFactor ?? '--';
  }

  get enthalpy() {
    return this.lastBatch?.enthalpy ?? { code: -1, description: 'no_data' };
  }

  get enthalpyCode() {
    return this.lastBatch?.enthalpy?.code ?? -1;
  }

  get lineage() {
    return this.stage != 'empty_batch' ? this.lastBatch?.lineage?.name : '--';
  }

  get sex() {
    return this.stage != 'empty_batch' ? this.lastBatch?.sex?.name : '--';
  }

  get age() {
    return this.stage != 'empty_batch' ? this.lastBatch?.age : '--';
  }

  get pcpProjections() {
    return this.stage != 'empty_batch' ? this.lastBatch?.pcp_projections : '--';
  }

  get entryWeight(){
    return this.stage != 'empty_batch' ? this.lastBatch?.entry_weight : '--';
  }

  get pcpTarget(){
    return this.stage != 'empty_batch' ? this.lastBatch?.pcp_target : '--';
  }

  get real_age() {
    return this.stage != 'empty_batch' ? this.lastBatch?.real_age : '--';
  }

  get weight() {
    return isNotNull(this.lastMetrics?.weight) ? Math.round(this.lastMetrics?.weight) : '--';
  }

  get gain() {
    return isNotNull(this.lastMetrics?.gain) ? Math.round(this.lastMetrics?.gain) : '--';
  }

  get uniformity() {
    return this.lastMetrics?.variation_coefficient ?? '--';
  }

  get performance() {
    return this.lastMetrics?.performance ?? '--';
  }

  get addressSubRegion() {
    return this.property?.address?.sub_region ?? '--';
  }

  get addressStreet() {
    return this.property?.address?.street ?? '--';
  }

  get openingDate() {
    return this.lastBatch?.opening_date
      ? format(new Date(this.lastBatch.opening_date), 'dd/MM/yyyy HH:mm')
      : '--';
  }

  get closingDate() {
    return this.lastBatch?.closing_date
      ? format(new Date(this.lastBatch.closing_date), 'dd/MM/yyyy HH:mm')
      : '--';
  }

  get weightProjection() {
    return isNotNull(this.lastBatch?.weight_projection?.weight_projection[0]?.predict_weight)
      ? Math.round(this.lastBatch?.weight_projection?.weight_projection[0]?.predict_weight)
      : '--';
  }

  get updatedWeightProjectionList() {
    return this.lastBatch?.weight_projection?.updated_weight_projection;
  }

  get weeks() {
    return this.lastBatch?.week ?? '--';
  }

  get real_weeks() {
    return this.lastBatch?.real_week ?? '--';
  }

  get currentWeight() {
    return this.lastMetrics?.weight;
  }

  get productionType() {
    return this.lastBatch?.production_type.name ?? '--';
  }

  get aerosacculitis() {
    return this.lastBatch?.aerosacculitis ?? '--';
  }

  get contaminated() {
    return this.lastBatch?.contaminated ?? '--';
  }

  getMaxGainData() {
    let weightList = this.lastBatch?.weight_projection?.updated_weight_projection || [];
    if (weightList.length) {
      let maxGain = Math.max.apply(
        Math,
        weightList.map((wp) => {
          return wp.predict_gain;
        })
      );

      return weightList.find((wp) => {
        return wp.predict_gain == maxGain;
      });
    }
    return null;
  }

  getIdealWeightData() {
    let weightList = this.lastBatch?.weight_projection?.updated_weight_projection || [];
    if (weightList.length) {
      let IDEAL_WEIGHT = 3200;

      return weightList.reduce((prev, curr) => {
        return Math.abs(curr.predict_weight - IDEAL_WEIGHT) <
          Math.abs(prev.predict_weight - IDEAL_WEIGHT)
          ? curr
          : prev;
      });
    }
    return null;
  }

  getTargetWeight() {
    return getPcpTargetValue(this.lastBatch, this.company);
  }

  getBox(identification = null) {
    if (identification == null) {
      return this.lastBatch?.boxes;
    }
    return this.lastBatch?.boxes.find((b) => b.identification === identification);
  }

  checkHasBox() {
    return this.lastBatch?.boxes.length != 0;
  }

  getBoxDevice(boxId, tag) {
    return this.devicesList.find((d) => d.box === boxId && d.tag === tag);
  }

  getDevice(type, position = null) {
    if (position) {
      return this.devicesList.find((d) => d.device_type === type && d.position === position);
    } else {
      return this.devicesList.find((d) => d.device_type === type);
    }
  }
}
