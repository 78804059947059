export const state = {
  pcpPlanningSheds: [],
  totalSheds: 0,
  reportSelectedList: [],
  slaughterWeightProjection: [],
  tableOptions: {
    sortBy: ["target_weight"],
    sortDesc: [true]
  }
}
