<template>
  <div class="filter-option">
    <font-awesome-icon icon="fas fa-times" />
    <span class="ml-1 font-bold">{{ $t(name) }}</span>:
    {{ $t(filterValue) }}
  </div>
</template>

<script>
export default {
  name: 'FilterOption',
  props: {
    name: { type: String },
    value: { type: [String, Array] },
  },
  computed: {
    filterValue() {
      if (Array.isArray(this.value)) {
        return this.value.join(' / ');
      } else {
        return this.value;
      }
    },
  },
};
</script>

<style lang="postcss">
  .filter-option {
    @apply rounded text-xs sm:text-sm bg-slate-700 text-white px-2 cursor-pointer mb-1
    hover:bg-slate-800 transition mx-1 first:ml-0 last:mr-0 flex items-center select-none;
  }
  .filter-option {
    padding-top: 0.1rem;
  }
  .filter-option {
    padding-bottom: 0.1rem;
  }
</style>
