import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

import { Comfort } from './comfort/index.js';
import { Environment } from './environment/index.js';
import { EnvironmentFilter } from './environmentFilter/index.js';
import { Interface } from './interface/index.js';
import { Nutrition } from './nutrition/index.js';
import { Pcp } from './pcp/index.js';
import { PcpPlanning } from './pcpPlanning/index.js';
import { User } from './user/index.js';
import { Alerts } from './alerts/index.js';

const vuexLocalStorage = new VuexPersist({
  key: 'vuex',
  storage: window.localStorage,
  reducer: (state) => ({
    user: {
      token: state.user.token,
      name: state.user.name,
      isGuest: state.user.isGuest,
      roles: state.user.roles
    },
    environment: {
      sheds: state.environment.sheds,
    },
    environmentFilter: {
      isMatrix: state.environmentFilter.isMatrix,
      isLayingBirds: state.environmentFilter.isLayingBirds,
      isHatch: state.environmentFilter.isHatch,
    },
    nutrition: {
      silos: state.nutrition.silos,
      selectedSilo: state.nutrition.selectedSilo
    },
    interface: {
      isExpanded: state.interface.isExpanded,
      moduleOpened: state.interface.moduleOpened,
      internalMenuSelected: state.interface.internalMenuSelected
    },
  }),
});

Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    user: User,
    environment: Environment,
    environmentFilter: EnvironmentFilter,
    comfort: Comfort,
    interface: Interface,
    nutrition: Nutrition,
    alerts: Alerts,
    pcp: Pcp,
    pcpPlanning: PcpPlanning,
  },
  plugins: [vuexLocalStorage.plugin]
});

export default store;
