<template>
  <div>
    <!-- Title  -->
      <font-awesome-icon class="icon" style="font-size: 0.8rem" v-if="disabled" icon="fa-solid fa-lock fa-fw" />

    <!-- Items -->
    <nav>
      <ul>
        <InternalMenuItem
          v-for="(menu, i) in menus" :key="i"
          :icon="menu.icon"
          :selected="menu.selected == true"
          :label="menu.label"
          :accordion="menu.accordion"
          :accordionMenus="menu.accordionMenu"
          :disabled="getIsDisabled(menu)"
          :route="getUrlWithArguments(menu.route)"
          :isSidebar="menu.isSidebar"
          :arguments="args"
          :hasInternalContent="menu.internal != null"
          :role="menu.role"
          >
        </InternalMenuItem>
      </ul>
    </nav>
  </div>
</template>

<script>
import InternalMenuItem from '@/components/sidebar/InternalMenuItem.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'InternalMenu',
  components: { InternalMenuItem },
  data: function () {
    return {
        args: this.arguments
    }
  },
  props: {
    title: { type: String, required: true },
    menus: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    arguments: { type: Array, default: () => [] },
    accordion: { type: Boolean, default: false },
    isSidebar: { type: Boolean, default: false },
    accordionMenus: { type: Array },
  },
  computed: {
    ...mapGetters('environmentFilter', ['getIsMatrix']),
    ...mapGetters('user', ['getBroilerRoles', 'getMatrixRoles']),
    ...mapGetters('environment', ['getSelectedShed']),
  },
  methods: {
    getIsDisabled(menu){
      if (menu.label == 'alerts/recent/' || menu.label == 'alerts/history/') {
        return true
      }

      if (menu.role == 'Nutrição' && menu.isSidebar == false && this.getSelectedShed.silos.length == 0) {
          return true
        } else if (this.getBroilerRoles != null) {
          if (this.getBroilerRoles.includes(menu.role) && !this.getIsMatrix) {
            return false
          }
        } else if (this.getMatrixRoles != null) {
          if (this.getMatrixRoles.includes(menu.role) && this.getIsMatrix) {
            return false
          }
        } else return true
    },
    getUrlWithArguments(route) {
      if (route == null || route == '') {
        return null;
      }
      let routeWithArguments = route;
      this.arguments.forEach(element => {
        routeWithArguments += element + '/';
      });
      return routeWithArguments
    },
  },
};
</script>
