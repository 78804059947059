import axios from 'axios';
import Vue from 'vue';
import jwt_decode from 'jwt-decode';
import router from '@/router';

let config = {
  baseURL: process.env.VUE_APP_API_URL || 'https://backend.trinovati.com/api/',
  headers: {
    'Content-Type': 'application/json',
  },
};

const api = axios.create(config);

if (localStorage.getItem('vue-session-key')) {
  api.interceptors.request.use(
    (configuration) => {
      const vuexToken = localStorage.getItem('vue-session-key');
      const jsonToken = JSON.parse(vuexToken);
      const token = jsonToken['jwt'];

      if (token) {
        const { exp } = jwt_decode(token);
        const currentTime = Date.now() / 1000;
        if (currentTime > exp) {
          router.app.$session.destroy();
          router.push('/login');
        } else {
          configuration.headers.Authorization = `Bearer ${token}`;
        }
      }

      return configuration;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}

Vue.prototype.$axios = api;

export { api };
