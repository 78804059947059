import { Shed } from "./Shed";
import { capitalize } from "@/utils";

export class Silo {
  constructor(data = null) {
    if (data !== null) {
      this.id = data.id;
      this.shed = new Shed(data.shed);
      this.manufacturer = data.manufacturer;
      this.brand = data.brand;
      this.capacity = data.nutrition?.capacity ?? '--';
      this.identification = data.identification === 'Hidrometry Data' ? '--' : data.identification;
      this.nutrition = data.nutrition;
      this.rationTypes = data.ration_types;
    }
  }

  get shedName() {
    return this.shed?.name ?? '--';
  }

  get core() {
    if (this.shed?.property?.property_type === "Núcleo") {
      return this.shed?.property?.name ?? '--';
    } else {
      return '--';
    }
  }

  get shedAge() {
    return this.shed?.lastBatch?.age ?? '--';
  }

  get free_weight() {
    return this.nutrition?.free_weight ?? '--';
  }

  get last_delivered_weight() {
    return this.nutrition?.last_delivered_weight ?? '--';
  }

  get consumed() {
    return this.nutrition?.consumed ?? '--';
  }

  get available_weight() {
    return this.nutrition?.available_weight ?? '--';
  }

  get total_delivered_weight() {
    return this.nutrition?.total_delivered_weight ?? '--';
  }

  get total_consumed() {
    return this.nutrition?.total_consumed ?? '--';
  }

  get filled_area_percentage() {
    return this.nutrition?.filled_area_percentage ? this.nutrition.filled_area_percentage :  '--';
  }

  get phase() {
    return capitalize(this.nutrition?.ration_type?.phase);
  }

  get status() {
    return this.nutrition?.status ?? 0;
  }
}
