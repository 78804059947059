export const mutations = {
  SET_SILOS(state, value) {
    state.silos = value;
  },
  SET_SILOS_SHED_ADD_RATION(state, value) {
    state.silosShed = value;
  },
  SET_RATION_TYPES_ADD_RATION(state, value) {
    state.rationTypeAddRation = value;
  },

  SET_FEED_CONTROLS(state, value) {
    state.feedControls = value;
  },

  SET_NUTRITION_VALUES(state, value) {
    state.nutritionValues = value;
  },

  SET_NUTRITION_IS_LOADED(state, value) {
    state.nutritionIsLoaded = value;
  },

  SET_NUTRITION_CARDS(state, value) {
    state.nutritionCards = value
  },

  SET_NUTRITION_CARDS_IS_LOADED(state, value) {
    state.nutritionCardsIsLoaded = value
  },

  SET_SILOS_CONSUMPTION_HISTORY_TOTAL(state, value) {
    state.silosConsumptionHistoryTotal = value
  },
  SET_SILOS_CONSUMPTION_HISTORY_AVERAGE_CONSUMPTION(state, value) {
    state.silosConsumptionHistoryAverageConsumption = value
  },

  SET_TOTAL_NUTRITION(state, value) {
    state.totalNutrition = value;
  },

  SET_TOTAL_SILOS(state, value) {
    state.totalSilos = value;
  },

  SET_SELECTED_SILO(state, value) {
    state.selectedSilo = value;
  },

  SET_SHOW_NUTRITION_CARDS(state, value) {
    state.showNutritionCards = value;
  },
  SET_SHOW_NUTRITION_TOTALS(state, value) {
    state.showNutritionTotals = value;
  },

  SET_RATION_TYPES(state, value) {
    state.rationTypes = value;
  },

  SET_DIALOG_STATE(state, isOpen) {
    state.dialogState = isOpen;
  },
  SET_DIALOG_LOADING(state, isLoading) {
    state.dialogLoading = isLoading
  },

  SET_SILO_OCCUPATION_HISTORIES(state, value) {
    state.siloOccupationHistory = value
  }
}
