export const state = {
    alerts: [],
    alertsHistory: [],
    totalAlertsHistory: [],
    alertHistoryIsLoaded: false,
    alertCards: [],
    showAlertCards: false,
    totalAlerts: 0,
    alertIsLoaded: false,
    alertCardClass: {
        '3': 'lethal-gradient',
        '2': 'critical-gradient',
        '1': 'primary-alert-gradient',
        '0': 'comfort-alert-gradient',
    },
    alertCardIcon: {
        '3': 'fas fa-utensils',
        '2': 'fas fa-chart-line',
        '1': 'fas fa-briefcase-medical',
        '0': 'fas fa-wind',
    },
    alertLabel: {
        '3': 'nutrition',
        '2': 'performance',
        '1': 'sanity',
        '0': 'ambience',
        '-1': 'no_data',
      },
};
