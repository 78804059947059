<template>
  <div
    class="bg-gradient-to-r rounded px-3 py-2 sm:py-3 xl:px-4 xl:py-3 text-white drop-shadow-lg w-full container-comfort cursor-pointer select-none"
    :class="getComfortCardClass[comfort]">
    <div class="flex flex-row items-center justify-between">
      <div class="flex flex-row items-center justify-start sm:flex-col sm:items-start">
        <div class="font-bold text-xl xl:text-2xl mr-1 sm:mr-0" v-if="!loading">{{ total }}</div>
        <div class="font-bold text-xl xl:text-2xl mr-1 sm:mr-0" v-else>--</div>
        <span class="text-xs xl:text-sm uppercase xl:tracking-wider -mb-1 sm:-mb-0">
          {{ $t(value) || $t('loading') + '...' }}
        </span>
      </div>
      <div class="flex flex-row items-center percentage" v-if="!loading">
        <div class="font-bold text-base xl:text-xl mr-1">{{ percentage }}%</div>
        <font-awesome-icon class="text-2xl xl:text-3xl" :icon="getComfortCardIcon[comfort]" />
      </div>
      <div class="flex flex-row items-center" v-else>
        <SpinnerLoading/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import SpinnerLoading from '@/components/ui/SpinnerLoading.vue';

export default {
  name: 'ComfortCard',
  components: { SpinnerLoading },
  props: {
    comfort: { type: Number, required: true },
    value: { type: String },
    total: { type: Number },
    percentage: { type: Number },
    loading: { type: Boolean, default: true },
  },
  computed: {
    ...mapGetters('comfort', ['getComfortCardClass', 'getComfortLabel', 'getComfortCardIcon']),
  },
};
</script>
