export const actions = {

  async setTotalComfortValues({ rootGetters, commit }) {
    const shedsEnthalpyCount = rootGetters['environment/getShedsEnthalpyCount']

    let comfortValues = shedsEnthalpyCount;

    commit('SET_COMFORT_VALUES', comfortValues);
    commit('SET_COMFORT_IS_LOADED', true)
  },

  resetEnthalpyValues({ commit }) {
    commit('SET_COMFORT_VALUES', null);
    commit('SET_COMFORT_IS_LOADED', null);
  },
};
