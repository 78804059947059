<template>
  <div class="relative w-full flex" :class="{ 'expanded': expanded }">
    <div @click="openDropdown = !openDropdown" 
      class="cursor-pointer w-full flex items-center hover:text-gray-700 text-gray-400 transition ease-in select-none"
      :class="{ 'justify-center': !expanded, 'px-4': expanded }">
      <img class="rounded-circle flag" :src="getFlagLanguage($i18n.locale)" />
      <span class="ml-2 font-bold text-xs" v-if="expanded">{{ $t($i18n.locale) }}</span>
    </div>
    <transition :name="header ? 'animation-filter' : 'animation-dropdown-right'">
      <div v-if="openDropdown" class="absolute z-50 w-200"
        :class="[ header ? 'top-full right-0 mt-2' : 'top-1/2 -translate-y-1/2 dropdown-right' ]">
        <div class="px-2 pt-2 pb-2 bg-white rounded-md box-shadow">
          <div class="flex flex-col">
            <div v-for="(locale, i) in $i18n.availableLocales" :key="i"
              @click="changeLanguage(locale)"
              class="flex flex-row items-start bg-transparent p-2 hover:bg-gray-200 transition cursor-pointer px-2 py-1 rounded text-xs sm:text-sm font-bold duration-300 ease-out select-none text-slate-600">
              <div class="flex items-center">
                <img class="rounded-circle w-6" :src="getFlagLanguage(locale)" />
                <span class="ml-2">{{ $t(locale) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'LanguageDropdown',
  props: {
    expanded: { type: Boolean, default: false },
    header: { type: Boolean, default: false },
  },
  data() {
    return {
      openDropdown: false,
    };
  },
  methods: {
    getFlagLanguage(language) {
      return require(`@/assets/languages/${language}.svg`);
    },
    changeLanguage(language) {
      localStorage.setItem('lang', language);
      this.$i18n.locale = language;
      this.openDropdown = !this.openDropdown;
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.openDropdown = false;
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.close);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close);
  },
};
</script>

<style lang="postcss">
  .box-shadow {
    box-shadow: 0 0.15rem 1.75rem 0 rgb(58 59 69 / 15%) !important;
  }

  .flag {
    @apply w-8;
  }
  .expanded .flag {
    @apply w-6;
  }

  .animation-dropdown-enter-active {
    transition: all 0.3s ease;
  }
  .animation-dropdown-leave-active {
    transition: all 0.3s ease;
  }
  .animation-dropdown-enter,
  .animation-dropdown-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }

  .dropdown-top-left {
    @apply -top-4 ml-2 left-full;
  }
  .dropdown-bottom-right {
    @apply right-0 top-full mt-4;
  }
</style>