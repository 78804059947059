<template>
  <li class="side-bar-menu-item internal">
    <div v-if="hasInternalContent"
      class="container-menu-item"
      :class="{ 'disabled': disabled, 'selected': selected }"
      >
        <font-awesome-icon class="icon" :icon="icon" />
        <span class="label">{{ $t(label) }}</span>
    </div>
    <router-link v-else :to="route || ''" class="w-full">
      <div class="container-menu-item" :class="{ 'selected': selected, 'disabled': disabled }">
        <font-awesome-icon class="icon" :icon="icon" />
        <span class="label">{{ $t(label) }}</span>
      </div>
    </router-link>

      <!-- Accordion -->
      <div v-if="accordion" class="container-menu-acc">
        <div v-for="(menu, i) in accordionMenus" :key="i">
          <router-link :to="getUrlWithArguments(menu)" class="w-1/2" :disabled="getDisabled(menu)">
            <div class="container-menu-sub" :class="getClass(menu)">
              <font-awesome-icon class="icon" :icon="menu.icon" />
              <span class="label">{{ $t(menu.label) }}</span>
            </div>
          </router-link>
        </div>
      </div>
  </li>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'InternalMenuItem',
  props: {
    selected: { type: Boolean, default: false },
    icon: { type: String, required: true },
    label: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    route: { type: String, default: '' },
    role: {type: String, default: null},
    hasInternalContent: { type: Boolean, default: false },
    arguments: { type: Array, default: () => [] },
    isSidebar: { type: Boolean, default: false },
    accordion: { type: Boolean, default: false },
    accordionMenus: { type: Array },
  },
  data() {
    return {
      expandedAccordion: false,
    };
  },
  computed: {
    ...mapGetters('user', ['getBroilerRoles', 'getMatrixRoles']),
    ...mapGetters('environment', ['getSelectedShed']),
  },
  methods: {
    getClass(menu) {
        if (menu.selected) {
          return 'selected'
        } else if (this.getSelectedShed.lastBatch.sex.external_id == 2 && menu.label == 'male') {
          return 'disabled'
        } else if (this.getSelectedShed.lastBatch.sex.external_id == 3 && menu.label == 'female') {
          return 'disabled'
        }
    },
    getDisabled(menu) {
      if (this.getSelectedShed.lastBatch.sex.external_id == 2 && menu.label == 'male') {
          return true
        } else if (this.getSelectedShed.lastBatch.sex.external_id == 3 && menu.label == 'female') {
          return true
        }
    },
    getUrlWithArguments(menu) {
      if (menu.route == null || menu.route == '') {
        return null;
      }
      let routeWithArguments = menu.route;
      this.arguments.forEach(element => {
        if (menu.label == "female") {
          if (this.getSelectedShed.lastBatch.sex.external_id == 3) {
            return routeWithArguments = this.$route.path
          }
          routeWithArguments += element + '/' + "2";
        } else if (menu.label == "male") {
          if (this.getSelectedShed.lastBatch.sex.external_id == 2) {
            return routeWithArguments = this.$route.path
          }
          routeWithArguments += element + '/' + "3";
        }
      });
      return routeWithArguments
    },
  },
}
</script>
