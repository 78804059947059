import { getAlertsHistory, getRecentAlerts } from '@/services/alerts';
import { getDateFormatted } from '@/utils/index';

function updateCardCounter(key, value, cardCounter) {
  cardCounter[key] += value;
}

export const actions = {
  async loadRecentAlerts({ rootGetters, commit }, { params, page, itemsPerPage }) {
    const token = rootGetters['user/getToken'];
    const response = await getRecentAlerts(
      token.access,
      itemsPerPage,
      (page - 1) * itemsPerPage,
      params
    );
    let listAlerts = [];
    let cardCounter = {
      Ambiência: 0,
      Sanidade: 0,
      Desempenho: 0,
      Nutrição: 0,
    };
    let alertCards = [];
    let totalAlerts = 0;

    if (response?.results?.length) {
      response.results.forEach((element) => {
        totalAlerts++;
        updateCardCounter(element.type, element.subtype_count, cardCounter);
        listAlerts.push({
          'id': element.shed_id,
          'shed': element.shed_name,
          'age': element.age,
          'type': element.type,
          'subtype': element.subtype,
          'message': element.message,
          'timestamp': getDateFormatted(element.timestamp),
        });
      });
    }

    function cardColorPick(cardCounter) {
      if (cardCounter > 0) return 3;
      else return 0;
    }

    Object.entries(cardCounter).forEach((card, index) => {
      alertCards.push({
        'alert': cardColorPick(card[1]),
        'alertIndex': index,
        'name': card[0],
        'value': card[1],
      });
    });


    commit('SET_ALERTS', listAlerts);
    commit('SET_ALERT_CARDS', alertCards);
    commit('SET_TOTAL_ALERTS', response.count);
    commit('SET_SHOW_ALERT_CARDS', true);
    commit('SET_ALERTS_IS_LOADED', true);
  },

  async loadAlertsHistory({ rootGetters, commit }, { params, page, itemsPerPage }) {
    const token = rootGetters['user/getToken'];
    const response = await getAlertsHistory(
      token.access,
      itemsPerPage,
      (page - 1) * itemsPerPage,
      params
    );
    let listAlerts = [];
    let totalAlerts = 0;

    if (response?.results?.length) {
      response.results.forEach((element) => {
        totalAlerts++;
        listAlerts.push({
          'id': element.shed_id,
          'shed': element.shed_name,
          'subtype_count': element.subtype_count,
        });
      });
    }

    commit('SET_ALERTS_HISTORY', listAlerts);
    commit('SET_TOTAL_ALERTS_HISTORY', totalAlerts);
    commit('SET_ALERTS_HISTORY_IS_LOADED', true);
  },
};
