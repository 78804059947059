export const state = {
  dialogState: false,
  dialogLoading: false,
  showNutritionCards: false,
  showNutritionTotals: false,
  silosConsumptionHistoryTotal: {},
  silosConsumptionHistoryAverageConsumption: {},
  silos: [],
  totalSilos: 0,
  selectedSilo: null,
  silosShed: null,
  rationTypeAddRation: [],
  feedControls: [],
  nutritionValues: [],
  nutritionCards: [],
  totalNutrition: [],
  siloOccupationHistory: [],
  nutritionIsLoaded: false,
  nutritionCardsIsLoaded: false,
  rationTypes: {},
  nutritionLabel: {
    '1': 'critical',
    '2': 'low',
    '3': 'satisfactory',
    '0': 'no_data',
  },
  nutritionBadgeClass: {
    '1': 'comfort-badge lethal-badge',
    '2': 'comfort-badge critical-badge',
    '3': 'comfort-badge comfortable-badge',
    '0': 'comfort-badge disabled-badge',
  },
  nutritionCardClass: {
    '6': 'bg-amber-900',
    '5': 'bg-amber-800',
    '4': 'bg-amber-700',
    '3': 'bg-amber-600',
    '2': 'bg-amber-500',
    '1': 'bg-amber-400',
    '0': 'bg-amber-300',
  },
  nutritionAliasNumberClass: {
    '3': 'alias-number lethal-alias-number',
    '2': 'alias-number critical-alias-number',
    '1': 'alias-number intermediary-alias-number',
    '0': 'alias-number comfortable-alias-number',
    '-1': 'alias-number default-alias-number',
  },
  nutritionIcon: {
    '1': 'fas fa-exclamation',
    '2': 'fas fa-exclamation',
    '3': 'fas fa-check',
    '0': 'fas fa-times',
  },
  nutritionCardIcon: {
    '4': 'fas fa-check-circle',
    '3': 'fas fa-exclamation-triangle',
    '2': 'fas fa-exclamation-triangle',
    '1': 'fas fa-exclamation-triangle',
    '0': 'fas fa-check-circle',
  }
}
