export const state = {
  shedsEnthalpyCount: null,
  sheds: [],
  pcpSheds: [],
  totalSheds: 0,
  selectedShed: {},
  selectedShedAmbientance: [],
  selectedShedAmbientanceFull: [],
  shedDivisionEnthalpy: [],
  lastBatch: {},
  shedsIsLoaded: false,
  singleProperty: false,
  loadedBatchId: undefined,
  dashboards: {
    'performance': {
      'pt-br': 'JesPid6br/performance-pt-br',
      'en': 'JesPid6en/performance-en',
      'es': 'JesPid6es/performance-es',
    },
    'performance_box': {
      'pt-br': 'JesPboxbr/performance-box-pt-br',
      'en': 'JesPboxen/performance-box-en',
      'es': 'JesPboxes/performance-box-es',
    },
    'slaughter-prediction': {
      'pt-br': 'rNNvNTe7k/slaughter_forecast-pt-br',
      'en': 'MW4Jooenz/slaughter_forecast-en',
      'es': 'A16i0T6nk/slaughter_forecast-es',
    },
    'pcp_projection': {
      'pt-br': 'pkDSVo5br/pcp_projection-pt-br',
      'en': 'pkDSVo5en/pcp_projection-en',
      'es': 'pkDSVo5es/pcp_projection-es',
    },
    'nutrition_one': {
      'pt-br': 'lZ7ifK1br/nutrition-one-pt-br',
      'en': 'lZ7ifK1en/nutrition-one-en',
      'es': 'lZ7ifK1es/nutrition-one-es',
    },
    'nutrition_two': {
      'pt-br': 'lZ7ifK2br/nutrition-two-pt-br',
      'en': 'lZ7ifK2en/nutrition-two-en',
      'es': 'lZ7ifK2es/nutrition-two-es',
    },
    'nutrition_three': {
      'pt-br': 'lZ7ifK3br/nutrition-three-pt-br',
      'en': 'lZ7ifK3en/nutrition-three-en',
      'es': 'lZ7ifK3es/nutrition-three-es',
    },
    'sanity': {
      'pt-br': 'Pld5TQ6br/sanity-pt-br',
      'en': 'Pld5TQ6en/sanity-en',
      'es': 'Pld5TQ6es/sanity-es',
    },
    'sanity_box': {
      'pt-br': 'Pld5boxbr/sanity-box-pt-br',
      'en': 'Pld5boxen/sanity-box-en',
      'es': 'Pld5boxes/sanity-box-es',
    },
    'sanity_bat': {
      'pt-br': '9sUTUbbIz/sanidade-matriz-cinco-baterias-pt-br',
      'en': '9sUTUbbIz/sanity-box-en',
      'es': '9sUTUbbIz/sanity-box-es',
    },
    'sanity_rec': {
      'pt-br': 'BM1gxBxbr/sanity-rec-pt-br',
      'en': 'BM1gxBxen/sanity-rec-en',
      'es': 'BM1gxBxes/sanity-rec-es',
    },
    'performance_eight_box': {
      'pt-br': 'VMUqFTxbr/performance_eight_box-pt-br',
      'en': 'VMUqFTxen/performance_eight_box-en',
      'es': 'VMUqFTxes/performance_eight_box-es',
    },
    'alerts': {
      'pt-br': 'GlE0LgUbr/alerts-pt-br',
      'en': 'GlE0LgUen/alerts-en',
      'es': 'GlE0LgUes/alerts-es',
    },
    'alerts_recent': {
      'pt-br': 'PbavLg2br/alerts_recent-pt-br',
      'en': 'PbavLg2en/alerts_recent-en',
      'es': 'PbavLg2es/alerts_recent-es',
    }
  },
};
