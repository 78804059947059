export const getters = {
  getSilos(state) {
    return state.silos;
  },

  getSilosShed(state) {
    return state.silosShed;
  },

  getTotalSilos(state) {
    return state.totalSilos;
  },

  getSelectedSilo(state) {
    return state.selectedSilo;
  },

  getNutritionValues(state) {
    return state.nutritionValues;
  },

  getNutritionCards(state) {
    return state.nutritionCards;
  },

  getNutritionCardsIsLoaded(state) {
    return state.nutritionCardsIsLoaded;
  },

  getTotalNutrition(state) {
    return state.totalNutrition;
  },

  getShowNutritionCards(state) {
    return state.showNutritionCards;
  },
  getShowNutritionTotals(state) {
    return state.showNutritionTotals;
  },

  getNutritionIsLoaded(state) {
    return state.nutritionIsLoaded;
  },

  getNutritionLabel(state) {
    return state.nutritionLabel;
  },

  getNutritionBadgeClass(state) {
    return state.nutritionBadgeClass;
  },

  getNutritionCardClass(state) {
    return state.nutritionCardClass;
  },

  getNutritionAliasNumberClass(state) {
    return state.nutritionAliasNumberClass;
  },

  getNutritionIcon(state) {
    return state.nutritionIcon;
  },

  getNutritionCardIcon(state) {
    return state.nutritionCardIcon;
  },

  getRationTypes(state) {
    return state.rationTypes;
  },

  getRationTypesAddRation(state) {
    return state.rationTypeAddRation;
  },

  getFeedControls(state) {
    return state.feedControls;
  },

  getDialogState(state) {
    return state.dialogState;
  },

  getDialogLoading(state) {
    return state.dialogLoading
  },

  getSiloOccupationHistory(state) {
    return state.siloOccupationHistory
  },

  getSilosConsumptionHistoryTotal(state) {
    return state.silosConsumptionHistoryTotal
  },
  getSilosConsumptionHistoryAverageConsumption(state) {
    return state.silosConsumptionHistoryAverageConsumption
  }
};
